import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";

const EditGroupDialog = ({
  open,
  onClose,
  onSave,
  onDelete, // Add onDelete prop
  groupName,
  setGroupName,
  entities,
  selectedEntities,
  onToggleEntity,
  isNewGroup = true, // Add a flag to determine if it's a new group
}) => {
  const theme = useTheme();
  const [filterText, setFilterText] = useState("");
  const [nameError, setNameError] = useState(false);

  // Get unavailable entities and their count
  const unavailableEntities = useMemo(() => {
    const availableIds = entities.map(
      (entity) => `${entity.integration}-${entity.id}`
    );
    return selectedEntities.filter((id) => !availableIds.includes(id));
  }, [entities, selectedEntities]);

  const unavailableCount = unavailableEntities.length;

  const handleClose = () => {
    setFilterText("");
    setNameError(false);
    onClose();
  };

  const handleSave = () => {
    if (!groupName.trim()) {
      setNameError(true);
      return;
    }

    // Uncheck all unavailable entities before saving
    const updatedSelection = selectedEntities.filter((entityId) => {
      const availableIds = entities.map(
        (entity) => `${entity.integration}-${entity.id}`
      );
      return availableIds.includes(entityId);
    });

    setFilterText("");
    setNameError(false);
    onSave(updatedSelection);
  };

  const handleNameChange = (e) => {
    setGroupName(e.target.value);
    if (nameError) {
      setNameError(false);
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const filteredEntities = entities.filter((entity) =>
    entity.name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{isNewGroup ? "Create Group" : "Edit Group"}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Group Name"
          value={groupName}
          onChange={handleNameChange}
          error={nameError}
          helperText={nameError ? "Group name is required" : ""}
          sx={{ mb: 2 }}
        />
        {unavailableCount > 0 && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {unavailableCount}{" "}
            {unavailableCount === 1 ? "entity is" : "entities are"} no longer
            available. They will be automatically removed upon saving.
          </Alert>
        )}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search entities..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography variant="subtitle1">Entities</Typography>
        <List sx={{ maxHeight: 300, overflow: "auto" }}>
          {filteredEntities.map((entity) => (
            <ListItem key={`${entity.integration}-${entity.id}`}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedEntities.includes(
                    `${entity.integration}-${entity.id}`
                  )}
                  onChange={() =>
                    onToggleEntity(`${entity.integration}-${entity.id}`)
                  }
                />
              </ListItemIcon>
              <ListItemText primary={entity.name} />
            </ListItem>
          ))}
          {filteredEntities.length === 0 && (
            <ListItem>
              <ListItemText
                primary="No matching entities found"
                sx={{ textAlign: "center", color: "text.secondary" }}
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {!isNewGroup && onDelete && (
          <Button onClick={handleDelete} color="error" sx={{ mr: "auto" }}>
            Delete Group
          </Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!groupName.trim()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupDialog;
