import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  CircularProgress,
  Alert,
  IconButton,
} from "@mui/material";
import { FaDiscord } from "react-icons/fa"; // Import Discord icon
import { useRecoilState } from "recoil";
import { userIdTokenState, userInfoState } from "../global-state";
import { useAuthCheck } from "../auth/auth-check";
import useApiCall from "../common/api-call";
import { useHistory } from "react-router-dom";

const WaitlistPage = () => {
  const { userAuthState, setUserAuthState, checkAuthState } = useAuthCheck();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();
  const history = useHistory();

  useEffect(() => {
    checkAuthState();
    if (userInfo && !userInfo.toWait && !userInfo.inWait) {
      history.push("/");
    }
  }, [userInfo, history]);

  const handleLogout = () => {
    history.push("/user-logout");
  };

  const handleJoinWaitlist = async () => {
    try {
      setIsLoading(true);

      const response = await apiCall("/waitlist/join", "POST", "");

      if (response.success) {
        // Update user info to reflect waitlist status
        setUserInfo((prev) => ({
          ...prev,
          toWait: false,
          inWait: true,
        }));
      } else {
        // Handle error scenario
        setError(response.message || "Something went wrong. Please try again.");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render loading state
  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Render error state
  if (error) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Alert severity="error">{error}</Alert>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => setError(null)}
          >
            Try Again
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        {/* Condition 1: User is eligible to join waitlist */}
        {userInfo.toWait && (
          <>
            <Typography variant="h4" gutterBottom>
              Join Our Waitlist
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleJoinWaitlist}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Join Waitlist"}
            </Button>
          </>
        )}

        {/* Condition 2: User is already in waitlist */}
        {userInfo.inWait && (
          <>
            <Typography variant="h4" gutterBottom>
              You&apos;re on the Waitlist
            </Typography>

            <Typography variant="body1" paragraph>
              Thanks for your interest! We&apos;ll onboard you very soon.
            </Typography>

            <IconButton
              href="https://discord.gg/mspCBJp4E7"
              color="inherit"
              aria-label="Discord"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: 2,
                transition: "transform 0.2s, color 0.2s",
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "#5865F2",
                },
              }}
            >
              <FaDiscord size={48} />
            </IconButton>

            <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
              Join our Discord community
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleLogout}
              sx={{ mt: 3 }}
            >
              Logout
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default WaitlistPage;
