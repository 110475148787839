export const init = async (sendAndWaitWs, scopeId) => {
  try {
    const request = {
      scopeId,
    };

    const response = await sendAndWaitWs("/chat/init", JSON.stringify(request));
    if (response.error) {
      console.log(response);
      return null;
    }
    console.log(response);
    return JSON.parse(response.payload);
  } catch (error) {
    console.log(error);
    return null;
  }
};
