import React, { useEffect, useState } from "react";
import { Box, Card, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../theme";

const GroupFilter = ({ sortBy, setSortBy, setFilterText }) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
    setFilterText(event.target.value);
  };

  const handleClear = () => {
    setSearchInput("");
    setFilterText("");
  };

  return (
    <Card
      sx={{
        mb: 1.5,
        width: "100%",
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          width: "100%",
        }}
      >
        <TextField
          fullWidth
          value={searchInput}
          onChange={handleSearchInputChange}
          placeholder="Filter text"
          variant="outlined"
          size="small"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.background.input,
              "& .MuiOutlinedInput-input": {
                padding: "8px 14px",
              },
            },
            endAdornment: searchInput && (
              <IconButton
                size="small"
                onClick={handleClear}
                sx={{ marginRight: -1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Card>
  );
};

export default GroupFilter;
