import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useRecoilState } from "recoil";
import {
  developerIdTokenState,
  userIdTokenState,
  userInfoState,
} from "../global-state";
import useApiCall from "./api-call";

const OAuthFlow = ({
  oAuthInfo,
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type) {
        switch (event.data.type) {
          case "OAUTH_SUCCESS":
            onSuccess();
            break;
          case "OAUTH_ERROR":
            onFailure();
            break;
        }
      }
    };

    // Add message listener
    window.addEventListener("message", handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onSuccess, onFailure]);

  const generateSessionId = () => {
    return `oauth_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  };

  const initOAuthFlow = () => {
    if (oAuthInfo) {
      const sessionId = generateSessionId();

      // Store handler info in localStorage instead of sessionStorage
      const handlerInfo = {
        integration: oAuthInfo.integration,
        integrationConnect: oAuthInfo.integrationConnect,
        scopeId: userInfo.activeScope.id,
        originUrl: window.location.href,
        timestamp: Date.now(),
      };
      localStorage.setItem(
        `oauth_session_${sessionId}`,
        JSON.stringify(handlerInfo)
      );

      const authorizationUrl = `${oAuthInfo.authUrl}?client_id=${
        oAuthInfo.clientId
      }&redirect_uri=${
        oAuthInfo.redirectUri
      }&response_type=code&access_type=offline&scope=${oAuthInfo.scopes.join(
        " "
      )}&state=${sessionId}`;

      window.open(authorizationUrl, "_blank");
    }
  };

  return (
    <Button variant="contained" onClick={initOAuthFlow}>
      Connect
    </Button>
  );
};

export default OAuthFlow;
