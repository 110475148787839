import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Grid,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import theme from "../theme";
import { useToast } from "../common/toast";
import { listAutomations } from "../automation/automation-service";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";

const TaskFlowTempView = () => {
  const [automations, setAutomations] = useState([]);
  const [filteredAutomations, setFilteredAutomations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [userInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();
  const { errorToast } = useToast();
  const history = useHistory();

  const handleListTaskFlows = async () => {
    setLoading(true);
    try {
      const resp = await listAutomations(
        apiCall,
        userInfo.activeScope.id,
        "temp",
        "task_flow"
      );
      if (resp && Array.isArray(resp.automations)) {
        const taskFlowAutomations = resp["automations"];

        const filtered = taskFlowAutomations
          .filter((automation) =>
            automation.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)));

        setAutomations(filtered);
        setFilteredAutomations(filtered);
      } else {
        setAutomations([]);
        setFilteredAutomations([]);
      }
    } catch (error) {
      console.error("Failed to fetch automations:", error);
      errorToast("Failed to fetch automations");
      setAutomations([]);
      setFilteredAutomations([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleListTaskFlows();

    // Update current time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // When searching, maintain the timestamp sorting
    const filtered = automations
      .filter((automation) =>
        automation.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)));

    setFilteredAutomations(filtered);
  }, [searchTerm, automations]);

  const handleRefresh = () => {
    handleListTaskFlows();
  };

  const openOverview = (data) => {
    history.push(`/automation/overview?id=${data.id}`);
  };

  // Function to truncate name if it's more than 2 lines
  const truncateName = (name) => {
    // Create a temporary element to measure line height
    const temp = document.createElement("div");
    temp.style.visibility = "hidden";
    temp.style.position = "absolute";
    temp.style.whiteSpace = "normal";
    temp.style.width = "200px"; // Match card width
    temp.style.font = "inherit";
    temp.textContent = name;
    document.body.appendChild(temp);

    // Get line height and count lines
    const lineHeight = parseFloat(window.getComputedStyle(temp).lineHeight);
    const height = temp.offsetHeight;
    const lines = Math.round(height / lineHeight);

    document.body.removeChild(temp);

    // If more than 2 lines, truncate
    if (lines > 2) {
      return name.slice(0, 80) + "...";
    }
    return name;
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter by name"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            minWidth: 200,
            backgroundColor: theme.palette.background.white,
          }}
        />
        <Button
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          disabled={loading}
          sx={{ textTransform: "none" }}
        >
          Refresh
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={1}>
          {filteredAutomations.length > 0 ? (
            filteredAutomations.map((automation) => (
              <Grid item xs={12} sm={6} md={4} key={automation.id}>
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: theme.palette.background.white,
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    cursor: "pointer",
                    transition: "background-color 0.2s",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  variant="outlined"
                  onClick={() => openOverview(automation)}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      p: 1.5,
                      "&:last-child": {
                        paddingBottom: 1.5,
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{
                        mb: 0.5,
                        flexGrow: 1,
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        fontWeight: 500,
                      }}
                    >
                      {truncateName(automation.name)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        display: "block",
                        textAlign: "left",
                        lineHeight: 1.2,
                      }}
                    >
                      {customFormat(dayjs(automation.timestamp), currentTime)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary" align="center">
                No automations found
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default TaskFlowTempView;
