import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  developerEmailState,
  developerIdTokenState,
  originalPathState,
  userInfoState,
} from "../global-state";
import TextField from "@mui/material/TextField";
import { Amplify, Auth } from "aws-amplify";
import { DEV_AUTH_CONFIG, USER_AUTH_CONFIG } from "../aws-config";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { getUserInfo, saveUserSetting } from "./setting-service";
import { useToast } from "../common/toast";
import InfoIcon from "@mui/icons-material/Info";
import useApiCall from "../common/api-call";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Developer = () => {
  const history = useHistory();

  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const { apiCall } = useApiCall();
  const [developerEmail, setDeveloperEmail] =
    useRecoilState(developerEmailState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const theme = useTheme();
  const [developerLoggedIn, setDeveloperLoggedIn] = useState(false);
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const path = useLocation();
  const { successToast, warningToast, errorToast } = useToast();
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    fetchDeveloperInfo();
  }, []);

  const fetchUserInfo = async () => {
    const resp = await getUserInfo(apiCall, userInfo);
    if (resp) {
      setUserInfo(resp);
    } else {
      errorToast("Error!");
    }
  };

  const fetchDeveloperInfo = async () => {
    try {
      Amplify.configure(DEV_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const email = session.getIdToken().payload["cognito:username"];
      setDeveloperIdToken(session.getIdToken().getJwtToken());
      setDeveloperEmail(email);
      setDeveloperLoggedIn(true);
    } catch (error) {
      setDeveloperLoggedIn(false);
    }
  };

  const handleScriptModeChange = async (e, v) => {
    const ns = { ...userInfo.setting };
    ns.scriptMode = v;
    await saveSettingChange(ns);
  };

  const handleDebugModeChange = async (e, v) => {
    const ns = { ...userInfo.setting };
    ns.debugMode = v;
    await saveSettingChange(ns);
  };

  const saveSettingChange = async (ns) => {
    const resp = await saveUserSetting(apiCall, ns);
    if (resp) {
      successToast("Saved");
      fetchUserInfo();
    } else {
      errorToast("Error!");
    }
  };

  const handleDeveloperLoginLogout = () => {
    if (developerLoggedIn) {
      handleDeveloperLogout();
    } else {
      handleDeveloperLogin();
    }
  };

  const handleDeveloperLogin = async () => {
    setOriginalPath(path.pathname + path.search + path.hash);
    history.push("/developer-login");
  };

  const handleDeveloperLogout = async () => {
    try {
      Amplify.configure(DEV_AUTH_CONFIG);
      await Auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleUserLogout = async () => {
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      await Auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="start"
      flexDirection="column"
      height="100%"
      p={2}
      width="100%"
    >
      <Box
        position="static"
        sx={{
          display: "flex",
          alignItems: "center",
          justfyContent: "flex-start",
          mb: 2,
        }}
      >
        {isSmallScreen && (
          <IconButton
            color="inherit"
            edge="start"
            sx={{ mr: 1 }}
            onClick={() => {
              history.replace("/setting");
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Developer
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={1} direction="column">
        {developerLoggedIn && (
          <Grid item>
            <Card
              sx={{
                width: "100%",
                backgroundColor: theme.palette.background.card,
                fontFamily: "Roboto, sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={2} direction="column">
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item xs={9}>
                      <Typography variant="body1" component="div">
                        Advanced
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} direction="column">
                    {userInfo.setting.engAccount && (
                      <Grid item container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body1" component="div">
                              Enable Debug Mode
                            </Typography>
                            <Tooltip title="Enable or disable debug mode.">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                        <Grid item xs container justifyContent="flex-end">
                          <Switch
                            checked={userInfo.setting.debugMode}
                            onChange={handleDebugModeChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body1" component="div">
                            Enable Scripting
                          </Typography>
                          <Tooltip title="Enable or disable scripting mode.">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid item xs container justifyContent="flex-end">
                        <Switch
                          checked={userInfo.setting.scriptMode}
                          onChange={handleScriptModeChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <Card
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.card,
              fontFamily: "Roboto, sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "all 0.2s ease",
            }}
          >
            <CardContent>
              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      component="div"
                      style={{ width: "100px" }}
                    >
                      Developer
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          value={
                            developerLoggedIn ? developerEmail : "Please login"
                          }
                          disabled
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleDeveloperLoginLogout}
                        >
                          {developerLoggedIn ? "Logout" : "Login"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Developer;
