import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import { checkExternalLogin, saveAuthRelay } from "./user-login-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { Box, CircularProgress } from "@mui/material";

const UserLoginCallback = () => {
  const history = useHistory();
  const { successToast, warningToast, errorToast } = useToast();
  const { apiCall } = useApiCall();

  useEffect(() => {
    handleCallback();
  }, []);

  const handleCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");

    const source = localStorage.getItem("login_source");
    localStorage.removeItem("login_source");
    let user;
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const email = session.getIdToken().payload["email"];
      const externalUsername = session.getAccessToken().payload["username"];
      const providerName = "Google";

      const resp = await checkExternalLogin(
        apiCall,
        email,
        providerName,
        externalUsername
      );
      if (resp) {
        if (resp.linked) {
          warningToast(`Account ${email} already exists. Auto merged.`);
          await sleep();
          user = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });

          await new Promise((resolve, reject) => {
            user.refreshSession(
              user.getSignInUserSession().getRefreshToken(),
              (err, session) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(session);
                }
              }
            );
          });
        } else {
          user = await Auth.currentAuthenticatedUser();
        }
      } else {
        throw new Error("login fail");
      }
    } catch (error) {
      console.error("Error during callback handling", error);
      errorToast("Sign-in Error!");
      history.push("/user-login");
      return;
    }

    if (source === "app") {
      console.log(user);
      const idToken = user.signInUserSession.idToken.jwtToken;
      const refreshToken = user.signInUserSession.refreshToken.token;
      const accessToken = user.signInUserSession.accessToken.jwtToken;
      const expirationTime = user.signInUserSession.idToken.payload.exp * 1000;

      // Create a JSON object for the AuthState
      const authState = {
        idToken,
        refreshToken,
        accessToken,
        expirationTime,
      };

      const resp = await saveAuthRelay(apiCall, authState);
      if (resp.data) {
        window.location.href = `mhomeai://auth/login?id=${resp.data}`;
      } else {
        errorToast("Error!");
      }
    } else {
      const path = new URLSearchParams(window.location.search).get("state");
      history.push(path ? decodeURIComponent(path) : "/");
    }
  };

  const sleep = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default UserLoginCallback;
