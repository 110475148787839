// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#475B63",
    },
    secondary: {
      main: "#FFD700",
    },
    background: {
      default: "#EAEEF0",
      white: "#FFFFFF",
      disabled: "#BDBDBD",
      button: "#475B63",
      nav: "#D3E1E8",
      card: "#FFFFFF",
      dialog: "#d19191",
      notification: "#EAEEF0",
      input: "#FFFFFF",
      dataGrid: "#FFFFFF",
      log2: "linear-gradient(135deg, rgba(30, 90, 180, 0.6) 0%, rgba(0, 150, 200, 0.6) 100%)",
      logo1: "#475B63",
      logo2: "#8fbdcf",
      l1: "#EAEEF0",
      l2: "#9fe3a8",
      l3: "#e1c9a8",
      l4: "#d19191",
      l5: "#475B63",
      l6: "#8fbdcf",
      l0: "#FFFFFF",
      c1: "#9fe3a8",
      c2: "#e1c9a8",
      c3: "#d19191",
      c0: "#FFFFFF",
    },
    text: {
      primary: "#333333",
      active: "#e1c9a8",
      invalid: "#d19191",
      white: "#ffffff",
      black: "#000000",
      gray: "#757575",
      validInput: "#475B63",
      disabledInput: "#a0a0a0",
      l1: "#1e90ff",
    },
    light: {
      l100: "#f0f0f0",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export default theme;
