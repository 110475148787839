import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  saveScope,
  updateMember,
  checkInvitation,
} from "../menu/scope-service";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import { useEventEmitter } from "../ws/event-context";
import { useRecoilState } from "recoil";
import { userIdTokenState } from "../global-state";
import { useAuthCheck } from "../auth/auth-check";

function InitScope() {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [loadingInvitationId, setLoadingInvitationId] = useState(null);
  const [newScopeName, setNewScopeName] = useState("");
  const [showJoinScope, setShowJoinScope] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [userIdToken, setUserIdToken] = useRecoilState(userIdTokenState);
  const { userAuthState, setUserAuthState, checkAuthState } = useAuthCheck();
  const { successToast, errorToast } = useToast();
  const { apiCall } = useApiCall();

  const { subscribe } = useEventEmitter();
  const [isAccept, setIsAccept] = useState(false);

  useEffect(() => {
    checkAuthState();
  }, []);

  useEffect(() => {
    if (showJoinScope && userIdToken) {
      fetchInvitations();
    }
  }, [showJoinScope, userIdToken]);

  const fetchInvitations = async () => {
    setInvitationLoading(true);
    setLoading(true);
    try {
      const resp = await checkInvitation(apiCall);
      if (Array.isArray(resp) && resp.length > 0) {
        setInvitations(resp);
      } else {
        setInvitations([]);
      }
      console.log(resp);
    } catch (error) {
      console.error("Error fetching invitations:", error);
      errorToast("Failed to load invitations");
    } finally {
      setInvitationLoading(false);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!newScopeName.trim()) return;

    setLoading(true);
    try {
      const scopeId = await saveScope(apiCall, null, newScopeName, true);
      if (scopeId) {
        goToScope();
      }
    } catch (error) {
      console.error("Error creating space:", error);
      errorToast("Failed to create space!");
    } finally {
      setLoading(false);
    }
  };

  const handleRejectInvitation = async (invitationScopeId) => {
    setLoadingInvitationId(invitationScopeId);
    try {
      let scopeId = null;
      invitations
        .filter((inv) => inv.scopeId === invitationScopeId)
        .map((inv) => {
          scopeId = inv.scopeId;
        });

      if (!scopeId) {
        errorToast("The space does not exist.Please refresh");
        return;
      }
      const resp = await updateMember(apiCall, scopeId, false);
      if (resp) {
        successToast("Successfully reject the invitation");
        setInvitations(
          invitations.filter((inv) => inv.scopeId !== invitationScopeId)
        );
        await fetchInvitations();
      } else {
        errorToast("Failed to reject invitation!");
      }
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      errorToast("Failed to reject invitation");
    } finally {
      setLoadingInvitationId(null);
    }
  };

  const handleAcceptInvitation = async (invitationScopeId) => {
    setLoadingInvitationId(invitationScopeId);
    try {
      let scopeId = null;
      invitations
        .filter((inv) => inv.scopeId === invitationScopeId)
        .map((inv) => {
          scopeId = inv.scopeId;
        });
      if (!scopeId) {
        errorToast("The space does not exist.Please refresh");
        return;
      }
      const resp = await updateMember(apiCall, scopeId, true, true);
      if (resp) {
        successToast("Successfully joined the space");
        setInvitations(
          invitations.filter((inv) => inv.scopeId !== invitationScopeId)
        );
        setIsAccept(true);
        await fetchInvitations();
      } else {
        errorToast("Failed to accept invitation!");
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
      errorToast("Failed to accept invitation");
    } finally {
      setLoadingInvitationId(null);
    }
  };

  const toggleView = () => {
    setShowJoinScope(!showJoinScope);
  };

  const goToScope = () => {
    history.push("/settings#scope");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f7fa",
        padding: isMobile ? 0 : 2,
      }}
    >
      <Paper
        elevation={isMobile ? 0 : 3}
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : 480,
          minHeight: isMobile ? "100vh" : "auto",
          borderRadius: isMobile ? 0 : 4,
          overflow: "hidden",
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "column",
          justifyContent: isMobile ? "flex-start" : "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            pt: isMobile ? 10 : 4,
            pb: 3,
            px: 3,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            maxWidth: 480,
            mx: "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 80,
              height: 80,
              borderRadius: "50%",
              backgroundColor: "#e3f2fd",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              mb: 2,
            }}
          >
            <HomeIcon sx={{ fontSize: 40, color: "#1976d2" }} />
          </Box>

          <Typography
            variant="h5"
            component="h1"
            sx={{
              fontWeight: 600,
              mb: 1,
            }}
          >
            {showJoinScope ? "Join Space" : "Welcome Home"}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
              mb: 3,
            }}
          >
            {showJoinScope
              ? "View and accept your space invitations"
              : "Let's create your first space to get started"}
          </Typography>

          {!showJoinScope ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                px: isMobile ? 2 : 0,
              }}
            >
              <TextField
                autoFocus
                fullWidth
                required
                label="Name Your Space"
                name="name"
                value={newScopeName}
                onChange={(e) => {
                  setNewScopeName(e.target.value);
                }}
                variant="outlined"
                placeholder="Space Name"
                disabled={loading}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />

              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={loading || !newScopeName.trim()}
                sx={{
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: "none",
                  fontSize: "1.1rem",
                  backgroundColor: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Create My Space"
                )}
              </Button>

              <Button
                variant="text"
                onClick={toggleView}
                sx={{
                  textTransform: "none",
                  color: theme.palette.text.secondary,
                  fontSize: "0.875rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
              >
                Skip creation and join space
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                px: isMobile ? 2 : 0,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  startIcon={<RefreshIcon />}
                  onClick={fetchInvitations}
                  disabled={invitationLoading}
                  sx={{ textTransform: "none" }}
                ></Button>
              </Box>

              {invitationLoading && (
                <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                  <CircularProgress size={24} />
                </Box>
              )}

              {!invitationLoading &&
                invitations.length > 0 &&
                invitations.map((invitation, index) => (
                  <Paper
                    key={`${invitation.scopeId}-${index}`}
                    sx={{
                      p: 1.5,
                      borderRadius: 2,
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <Typography sx={{ mr: 1 }}>
                          {invitation.inviterName} invite you to join&nbsp;
                          {invitation.scopeName}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            handleAcceptInvitation(invitation.scopeId)
                          }
                          disabled={loadingInvitationId === invitation.scopeId}
                        >
                          {loadingInvitationId === invitation.scopeId ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Accept ✔"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ ml: 1 }}
                          onClick={() =>
                            handleRejectInvitation(invitation.scopeId)
                          }
                          disabled={loadingInvitationId === invitation.scopeId}
                        >
                          {loadingInvitationId === invitation.scopeId ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Reject ✘"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                ))}

              {!invitationLoading && invitations.length === 0 && (
                <Box
                  sx={{
                    textAlign: "center",
                    mb: 2,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    No invitations found
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    You haven&apos;t received any space invitations yet
                  </Typography>
                </Box>
              )}

              <Button
                variant="contained"
                onClick={goToScope}
                disabled={loading || !isAccept}
                sx={{
                  mt: 2,
                  borderRadius: 2,
                  textTransform: "none",
                  fontSize: "1.1rem",
                  backgroundColor: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Enter My Scope"
                )}
              </Button>

              <Button
                variant="text"
                onClick={toggleView}
                startIcon={<ArrowBackIcon />}
                sx={{
                  textTransform: "none",
                  color: theme.palette.text.secondary,
                  fontSize: "0.875rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
              >
                Back to create space
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default InitScope;
