export const listSchedules = async (apiCall, scopeId) => {
  try {
    const response = await apiCall(`/schedule/list?scope=${scopeId}`, "GET");
    if (response.error) {
      return null;
    }
    return response;
  } catch (error) {
    return null;
  }
};
