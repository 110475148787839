import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import OAuthFlow from "../common/oauth";
import DynamicMdxRenderer from "../common/mdx";
import {
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { completeConnectIntegration } from "./integration-service";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";

const ConnectDialog = ({
  open,
  integration,
  connectInfo,
  onClose,
  onSuccess,
  onFailure,
}) => {
  const theme = useTheme();
  const { apiCall } = useApiCall();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userInfo] = useRecoilState(userInfoState);
  const [currentGuideIndex, setCurrentGuideIndex] = useState(0);
  const [inputValues, setInputValues] = useState(new Map());
  const [canProceed, setCanProceed] = useState(true);

  useEffect(() => {
    setCurrentGuideIndex(0);
    validateCurrentPage(0);
  }, [open]);

  const handleComplete = async () => {
    onClose();
    if (connectInfo.oauthConnect) {
      onSuccess();
    } else if (connectInfo.customConnect) {
      const resp = await completeConnectIntegration(
        apiCall,
        userInfo.activeScope.id,
        integration,
        { vars: Object.fromEntries(inputValues) }
      );
      if (resp && resp.success) {
        onSuccess();
      } else {
        onFailure();
      }
    }
  };

  const handleFailure = () => {
    onClose();
    onFailure();
  };

  const handleInputChange = (varName, value) => {
    console.log(varName, value);
    inputValues.set(varName, value);
    // Check if the current MDX has any TextInput and if it has value
    validateCurrentPage(currentGuideIndex);
  };

  const validateCurrentPage = (index) => {
    if (!connectInfo?.customConnect?.guides?.[index]) {
      return;
    }

    const currentMdx = connectInfo.customConnect.guides[index];

    // Find all TextInput components and their var attributes
    const textInputRegex = /<TextInput[^>]*var="([^"]+)"[^>]*>/g;
    const textInputMatches = [...currentMdx.matchAll(textInputRegex)];

    if (textInputMatches.length > 0) {
      // Check if all TextInput components have values
      const allInputsHaveValues = textInputMatches.every((match) => {
        const varName = match[1]; // Get the var name from the regex capture group
        return !!inputValues.get(varName) || inputValues.get(varName) === false;
      });

      setCanProceed(allInputsHaveValues);
    } else {
      // If there are no TextInput components, user can proceed
      setCanProceed(true);
    }
  };

  const handleNext = async () => {
    if (currentGuideIndex < connectInfo.customConnect.guides.length - 1) {
      const index = currentGuideIndex + 1;
      setCurrentGuideIndex(index);
      validateCurrentPage(index);
    } else {
      await handleComplete();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          height: isMobile ? "100%" : "auto",
          maxHeight: "100%",
          borderRadius: isMobile ? 0 : 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          gap: 1,
          minHeight: 56,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
          {"Connect to " + integration}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: isMobile ? "calc(100vh - 120px)" : "400px",
            p: 2,
          }}
        >
          {connectInfo && connectInfo.oauthConnect ? (
            <>
              <Typography variant="body1" sx={{ mb: 3 }}>
                {integration} connects to an external service. You will be
                redirected to the service provider site for authorization.
              </Typography>
              <Box sx={{ mt: 2 }}>
                {connectInfo && connectInfo.oauthConnect && (
                  <OAuthFlow
                    oAuthInfo={connectInfo.oauthConnect}
                    onSuccess={handleComplete}
                    onFailure={handleFailure}
                  />
                )}
              </Box>
            </>
          ) : connectInfo && connectInfo.customConnect ? (
            <>
              <Box sx={{ width: "100%", flex: 1, overflow: "auto" }}>
                <DynamicMdxRenderer
                  mdxContent={
                    connectInfo.customConnect.guides[currentGuideIndex]
                  }
                  onInputChange={handleInputChange}
                />
              </Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 3,
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  disabled={!canProceed}
                  onClick={handleNext}
                >
                  {currentGuideIndex ===
                  connectInfo.customConnect.guides.length - 1
                    ? "Done"
                    : "Next"}
                </Button>
              </Stack>
            </>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectDialog;
