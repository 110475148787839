import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

const TextSaveInput = ({ valueSpec, onChange, style = "standard" }) => {
  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (valueSpec.value !== value) {
      setValue(valueSpec.value);
    }
  }, [valueSpec]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleEditToggle = () => {
    if (isEditing && valueSpec.value !== value) {
      onChange(value);
    }
    setIsEditing(!isEditing);
  };

  const compactStyles = {
    "& .MuiInputBase-root": {
      height: "100%",
      padding: "4px 8px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiInputBase-input": {
      height: "100% !important",
      overflow: "auto !important",
      padding: "0 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 1,
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1rem",
      transform: "translate(8px, 4px) scale(0.75)",
      "&.MuiFormLabel-filled, &.Mui-focused": {
        transform: "translate(8px, -9px) scale(0.75)",
      },
    },
  };

  const standardStyles = {
    "& .MuiInputBase-root": {
      height: "100%",
      minHeight: "56px",
    },
    "& .MuiInputBase-input": {
      padding: "4px 4px",
      height: "100%",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {valueSpec.name && (
        <Grid item xs>
          <Typography variant="h6">{valueSpec.name}</Typography>
        </Grid>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          flex: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            height: "100%",
            position: "relative",
          }}
        >
          <TextField
            label={valueSpec.name || ""}
            value={value}
            onChange={handleInputChange}
            fullWidth
            multiline
            size="small"
            disabled={!isEditing}
            sx={{
              height: "100%",
              ...(style === "compact" ? compactStyles : standardStyles),
            }}
            InputProps={{
              sx: {
                height: "100%",
              },
            }}
            inputProps={{
              style: {
                height: "100%",
                overflow: "auto",
              },
            }}
          />
        </Box>
        <IconButton
          onClick={handleEditToggle}
          sx={{
            ml: 1,
            color: isEditing ? "primary.main" : "text.secondary",
            alignSelf: "flex-start",
            mt: 1,
          }}
        >
          {isEditing ? <CheckIcon /> : <EditIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default TextSaveInput;
