import React from "react";
import { useHistory } from "react-router-dom";
import IntegrationListView from "../integration/integration-list-view";
import HistoryIcon from "@mui/icons-material/History";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import BarChartIcon from "@mui/icons-material/BarChart";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ExtensionIcon from "@mui/icons-material/Extension";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";

import { Box, Card, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { platformState } from "../global-state";

const Menu = ({ showFilter, setShowFilter }) => {
  const history = useHistory();
  const [platform, setPlatform] = useRecoilState(platformState);
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 2,
        height: "auto",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {/* Scope Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/space");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <MapsHomeWorkIcon sx={{ mr: 1 }} />
                <Typography>Space</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/*integration*/}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/integerations");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ExtensionIcon sx={{ mr: 1 }} />
                <Typography>Integrations </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Phone Section */}
        {platform.source === "app" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                fontFamily: "Roboto,sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => {
                history.push("/phone/setting");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PhonelinkSetupIcon sx={{ mr: 1 }} />
                  <Typography>Phone Setting</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}

        {/* Hub Section */}
        {platform.source === "app" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                fontFamily: "Roboto,sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => {
                history.push("/hub/setting");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DeviceHubIcon sx={{ mr: 1 }} />
                  <Typography>Hub Setting</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}

        {/* History Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/automation/history");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HistoryIcon sx={{ mr: 1 }} />
                <Typography>History</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Schedule Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/schedules");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ScheduleIcon sx={{ mr: 1 }} />
                <Typography>Schedule</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Automation Statistics Section */}
        {process.env.NODE_ENV !== "production" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                fontFamily: "Roboto,sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => {
                history.push("/automation/statistics");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BarChartIcon sx={{ mr: 1 }} />
                  <Typography>Automation Statistics </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Menu;
