import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Box from "@mui/material/Box";
import {
  MobileDateTimePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";

dayjs.extend(utc);
dayjs.extend(timezone);

const TimestampInput = ({ valueSpec, onChange, embedded = true }) => {
  const [value, setValue] = useState(
    dayjs.tz(parseInt(valueSpec.value), dayjs.tz.guess())
  );

  useEffect(() => {
    setValue(dayjs.tz(parseInt(valueSpec.value), dayjs.tz.guess()));
  }, [valueSpec]);

  const handleTimestampChange = (newValue) => {
    setValue(newValue);
    onChange(newValue.valueOf().toString());
  };

  const commonProps = {
    value: value,
    onChange: handleTimestampChange,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && <Typography variant="h6">{valueSpec.name}</Typography>}
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {embedded ? (
            <StaticDateTimePicker
              {...commonProps}
              displayStaticWrapperAs="desktop"
              slots={{
                actionBar: null,
              }}
              slotProps={{
                toolbar: {
                  hidden: false,
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    "& .MuiInputBase-root": {
                      height: "32px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "0 8px",
                    },
                  },
                },
              }}
              sx={{
                width: "100%",
                "& .MuiPickersLayout-root": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                },
                "& .MuiPickersLayout-contentWrapper": {
                  margin: 0,
                },
                // Hide action buttons
                "& .MuiDialogActions-root": {
                  display: "none",
                },
                // Center the calendar and clock
                "& .MuiDateCalendar-root, & .MuiClock-root": {
                  margin: "0 auto",
                },
                // Adjust tabs spacing
                "& .MuiTabs-root": {
                  minHeight: "40px",
                },
                "& .MuiPickersLayout-actionBar": {
                  display: "none",
                },
              }}
            />
          ) : (
            <MobileDateTimePicker
              {...commonProps}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    flex: 1,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: "26px",
                    },
                    "& .MuiInputBase-input": {
                      height: "100%",
                      padding: "0 8px",
                      display: "flex",
                      alignItems: "center",
                    },
                  },
                },
              }}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                "& .MuiTextField-root": {
                  flex: 1,
                  display: "flex",
                },
                "& .MuiPickersLayout-root": {
                  minHeight: "auto",
                },
              }}
            />
          )}
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default TimestampInput;
