// ProviderListView.jsx
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ProviderFilter from "./provider-filter";
import { useTheme } from "@mui/material/styles";
import ProviderDetailDialog from "./provider-detail-dialog";
import {
  disconnectIntegration,
  installIntegration,
  listProviders,
  startConnectIntegration,
  uninstallIntegration,
} from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import ConnectDialog from "./connect-dialog";

const ProviderCard = ({ provider, theme, onSelect }) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: 90,
        border: "none",
        transition: "all 0.2s ease-in-out",
        WebkitTapHighlightColor: "transparent",
        touchAction: "manipulation",
        userSelect: "none",
        backgroundColor: provider.installed
          ? theme.palette.background.paper
          : theme.palette.background.disabled,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:active": {
          backgroundColor: theme.palette.action.selected,
        },
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: provider.installed
              ? theme.palette.background.paper
              : theme.palette.background.disabled,
          },
        },
        cursor: "pointer",
      }}
      onClick={() => onSelect(provider)}
    >
      <CardContent
        sx={{
          p: "12px !important",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: 1.2,
            }}
          >
            {provider.name}
          </Typography>
          {provider.installed && (
            <DownloadDoneIcon
              sx={{
                fontSize: 16,
                color: theme.palette.success.main,
              }}
            />
          )}
        </Box>
        <Typography
          variant="caption"
          color="textSecondary"
          noWrap
          sx={{ mb: "auto" }}
        >
          {provider.org}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {provider.installed
            ? provider.multiConnect
              ? `${provider.connects.length} Connection${
                  provider.connects.length !== 1 ? "s" : ""
                }`
              : "Connected"
            : "Not Connected"}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ProviderListView = ({ providers, showFilter, onLoadIntegration }) => {
  const [providerFilterText, setProviderFilterText] = useState("");
  const [providerSortBy, setProviderSortBy] = useState("alphabet");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const { apiCall } = useApiCall();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const theme = useTheme();

  const handleProviderInstall = async (provider) => {
    if (provider.installType === "CONNECT") {
      await handleConnect(provider.integration);
    } else {
      await handleInstall(provider.integration);
    }
  };

  const handleProviderUninstall = async (provider, connectId) => {
    if (provider.installType === "CONNECT") {
      await handleDisconnect(provider.integration, connectId);
    } else {
      await handleUninstall(provider.integration);
    }
  };

  const handleInstall = async (integration) => {
    const resp = await installIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      successToast("Installed");
      onLoadIntegration();
      setSelectedProvider(null);
    } else {
      errorToast("Error!");
    }
  };

  const handleUninstall = async (integration) => {
    const resp = await uninstallIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      successToast("Uninstalled");
      onLoadIntegration();
      setSelectedProvider(null);
    } else {
      errorToast("Error!");
    }
  };

  const handleConnectComplete = async () => {
    successToast("Connected");
    onLoadIntegration();
    setSelectedProvider(null);
  };

  const handleConnect = async (integration) => {
    const resp = await startConnectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      setConnectInfo(resp);
      setConnectIntegrationName(integration);
      setOpenConnect(true);
    }
  };

  const handleDisconnect = async (integration, connectId) => {
    const resp = await disconnectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration,
      connectId
    );
    if (resp) {
      successToast("Disconnected");
      onLoadIntegration();
      setSelectedProvider(null);
    } else {
      errorToast("Error!");
    }
  };

  const sortedProviders = providers
    .filter(
      (provider) =>
        provider.name
          .toLowerCase()
          .includes(providerFilterText.toLowerCase()) &&
        provider.name.toLowerCase() !== "govee" // TODO: remove
    )
    .sort((a, b) => {
      if (providerSortBy === "alphabet") {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName);
      }
      return 0;
    });

  return (
    <Box>
      {showFilter && (
        <ProviderFilter
          sortBy={providerSortBy}
          setSortBy={setProviderSortBy}
          setFilterText={setProviderFilterText}
        />
      )}
      <Grid container spacing={1.5}>
        {sortedProviders.map((provider) => (
          <Grid item xs={6} sm={4} md={2} lg={2} key={provider.integration}>
            <ProviderCard
              provider={provider}
              theme={theme}
              onSelect={setSelectedProvider}
            />
          </Grid>
        ))}
      </Grid>
      <ProviderDetailDialog
        open={Boolean(selectedProvider)}
        provider={selectedProvider}
        onClose={() => {
          setSelectedProvider(null);
        }}
        onDisconnect={handleProviderUninstall}
        onNewConnect={handleProviderInstall}
      />
      <ConnectDialog
        open={openConnect}
        integration={connectIntegrationName}
        connectInfo={connectInfo}
        onClose={() => setOpenConnect(false)}
        onSuccess={handleConnectComplete}
        onFailure={() => errorToast("Connect Error!")}
      />
    </Box>
  );
};

export default ProviderListView;
