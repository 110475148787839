import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const WaitingChatCard = React.memo(({ message }) => {
  const [dots, setDots] = React.useState("");
  const theme = useTheme();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? "." : prev + "."));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "80%",
        minWidth: "30px",
        backgroundColor: theme.palette.background.c0,
        borderRadius: "12px",
        padding: "8px",
      }}
    >
      <Typography variant="body1">
        {message} {dots}
      </Typography>
    </Box>
  );
});

WaitingChatCard.displayName = "WaitingCard";

export default WaitingChatCard;
