import { atom } from "recoil";

export const platformState = atom({
  key: "platform",
  default: null,
});

export const originalPathState = atom({
  key: "path",
  default: "/",
});

export const settingBackPathState = atom({
  key: "settingBackPath",
  default: "",
});

export const userIdTokenState = atom({
  key: "userIdToken",
  default: "",
});

export const linkOrgIdState = atom({
  key: "linkOrgId",
  default: "",
});

export const linkOrgUserIdState = atom({
  key: "linkOrgUserId",
  default: "",
});

export const developerIdTokenState = atom({
  key: "developerIdToken",
  default: "",
});

export const developerEmailState = atom({
  key: "developerEmail",
  default: "",
});

export const userInfoState = atom({
  key: "userInfo",
  default: {},
});
