import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useWebSocket } from "../ws/websocket";
import LoadingSpinner from "../common/loading";
import { useEventEmitter } from "../ws/event-context";
import InstructionInput from "../assistant/instruction-input";
import { useBackdrop } from "../common/backdrop";
import AutomationEditCardContent from "./automation-edit-card-content";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { complete, stt } from "../assistant/assistant-service";
import useApiCall from "../common/api-call";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import { useToast } from "../common/toast";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AutomationIcon from "@mui/icons-material/AutoFixHigh";
import AutomationErrorCardContent from "./automation-error-card-content";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { v4 as uuidv4 } from "uuid";

const OverviewConfigView = () => {
  const stackRef = useRef([]);
  const stackIndexRef = useRef(-1);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [canReplay, setCanReplay] = useState(false);
  const [curInstruction, setCurInstruction] = useState("");
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);

  const history = useHistory();
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { subscribe } = useEventEmitter();
  const [params, setParams] = useState({});
  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState("");
  const [errorPayload, setErrorPayload] = useState(null);
  const { apiCall } = useApiCall();
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const [userInfo] = useRecoilState(userInfoState);
  const { successToast, warningToast, errorToast } = useToast();

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "_closed_") {
        openBackdrop("Please Refresh");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const p = { draft: null };
    for (const [key, value] of searchParams.entries()) {
      if (key === "id") {
        p["automationId"] = value;
      } else {
        p[key] = value;
      }
    }

    if (ws && ws.readyState === WebSocket.OPEN) {
      closeBackdrop();
      setParams(p);
      if (stackIndexRef.current === -1) {
        stackRef.current.push({
          nextContext: {},
          params: p,
          update: p.mode !== "new",
        });
        stackIndexRef.current += 1;
      }
      setLoading(false);
    }
  }, [ws, ws?.readyState]);

  const pushContextStack = (cur, replace) => {
    stackRef.current.splice(stackIndexRef.current + 1);
    if (replace) {
      stackRef.current[stackIndexRef.current] = cur;
    } else {
      stackRef.current.push(cur);
      stackIndexRef.current += 1;
    }
    setCanUndo(stackIndexRef.current > 0);
    setCanRedo(stackIndexRef.current < stackRef.current.length - 1);
    setCanReplay(stackRef.current[stackIndexRef.current].currentContext);
    console.log(stackRef.current);
  };

  const handleUndo = () => {
    stackIndexRef.current = stackIndexRef.current - 1;
    handleUndoRedo();
  };

  const handleRedo = () => {
    stackIndexRef.current = stackIndexRef.current + 1;
    handleUndoRedo();
  };

  const handleUndoRedo = () => {
    setCanUndo(stackIndexRef.current > 0);
    setCanRedo(stackIndexRef.current < stackRef.current.length - 1);
    setCanReplay(stackRef.current[stackIndexRef.current].currentContext);
    const cur = stackRef.current[stackIndexRef.current];
    console.log(stackRef.current);
    if (cur.params) {
      setParams(cur.params);
      setError(null);
      setErrorType(null);
      setErrorPayload(null);
    } else if (cur.errorType) {
      setParams(null);
      setErrorType(cur.errorType);
      setErrorPayload(cur.errorPayload);
      setError(null);
    } else if (cur.error) {
      setError(cur.error);
      setParams(cur.null);
      setErrorType(null);
      setErrorPayload(null);
    }
  };

  const handleAutomationSave = async (automationId) => {
    history.replace(`/automation/overview?id=${automationId}`);
    var newParams = {
      ...params,
      automationId,
      premadeId: null,
      premadeVersion: null,
      draft: null,
    };
    setParams(newParams);
    stackRef.current = [
      { nextContext: {}, params: newParams, update: newParams.mode !== "new" },
    ];
    stackIndexRef.current = 0;
    setCanUndo(false);
    setCanRedo(false);
    setCanReplay(false);
  };

  const handleAutomationChangeSummary = async (summary) => {
    if (stackRef.current[stackIndexRef.current]) {
      stackRef.current[
        stackIndexRef.current
      ].nextContext.automationDescription = summary;
    }
  };

  const handleDelete = async (id, type) => {
    if (type === "TASK_FLOW") {
      history.push("/taskflow/list");
    } else {
      history.push("/automation/list");
    }
  };

  const handleSendMessage = async (instruction, audioBlob, replay) => {
    const cur = {};
    const nc = replay
      ? stackRef.current[stackIndexRef.current].currentContext
      : stackRef.current[stackIndexRef.current].nextContext;
    const isError =
      !!stackRef.current[stackIndexRef.current].error ||
      !!stackRef.current[stackIndexRef.current].errorType;
    const replace = replay || isError;
    console.log(stackRef.current);
    cur.instruction = instruction;
    cur.currentContext = nc;
    let isUpdate = false;
    if (replay) {
      isUpdate = stackRef.current[stackIndexRef.current].update;
    } else {
      if (isError) {
        isUpdate = stackRef.current[stackIndexRef.current].update;
      } else {
        isUpdate = true;
      }
    }
    const requestType = isUpdate ? "UI_AUTOMATION_EDIT" : "UI_AUTOMATION_NEW";

    setGenerating(true);
    setError(null);

    let ins = instruction;
    if (audioBlob) {
      ins = await stt(apiCall, audioBlob);
      if (!ins) {
        throw new Error("Failed to convert speech to text");
      }
      console.log(ins);
    }
    setCurInstruction(ins);
    cur.instruction = ins;

    const packContext = {
      ...nc,
      instruction: ins,
      automationId: params.automationId,
    };

    const id = uuidv4();
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "/assistant/event") {
        const payload = JSON.parse(data.payload);
        // setAssistantEventMessage(payload.message);
      }
    });
    const resp = await complete(
      sendAndWaitWs,
      id,
      userInfo.activeScope.id,
      "",
      userInfo.activeScope.untrusted,
      packContext,
      0,
      userInfo.setting.debugMode,
      requestType
    );
    unsubscribe();
    if (resp && resp.messages) {
      let gen = false;
      console.log(resp);
      cur.currentContext = resp.currentContext;
      cur.nextContext = resp.nextContext;
      cur.update = isUpdate;
      for (let i = resp.messages.length - 1; i >= 0; i--) {
        const newMessage = resp.messages[i];
        if (newMessage.type === "AUTOMATION") {
          const p = {
            automationId: newMessage.data.automationId,
            draft: newMessage.content,
          };
          setParams(p);
          cur.params = p;
          gen = true;
          break;
        } else if (newMessage.type === "AUTOMATION_ERROR") {
          setParams(null);
          setErrorType(newMessage.data.type);
          setErrorPayload(JSON.parse(newMessage.data.payload));
          cur.errorType = newMessage.data.type;
          cur.errorPayload = JSON.parse(newMessage.data.payload);
          gen = true;
          break;
        } else if (newMessage.type === "TEXT") {
          setError(newMessage.content);
          cur.error = newMessage.content;
          gen = true;
          break;
        }
      }
      if (!gen) {
        setError("An error occurred.");
      }
    } else {
      setError("An error occurred.");
      cur.error = "An error occurred.";
    }
    pushContextStack(cur, replace);
    setCurInstruction("");
    setGenerating(false);
  };

  const renderContent = () => {
    if (!errorType) {
      if (generating) {
        return (
          <Box sx={{ p: 3, maxWidth: 800, mx: "auto" }}>
            <Card
              elevation={0}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: "12px",
                backgroundColor: "background.card",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                }}
              >
                <CircularProgress size={40} sx={{ mb: 3 }} />
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {params.mode === "new"
                    ? "Generating Automation ..."
                    : "Updating Automation ..."}
                </Typography>
                {curInstruction && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ mt: 2, mx: 4 }}
                  >
                    &quot;{curInstruction}&quot;
                  </Typography>
                )}
              </Box>
            </Card>
          </Box>
        );
      } else if (error) {
        return (
          <Box sx={{ p: 3, maxWidth: 800, mx: "auto" }}>
            <Card
              elevation={0}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: "12px",
                backgroundColor: "background.card",
              }}
            >
              <Box sx={{ py: 3 }}>
                <ErrorOutlineIcon
                  sx={{ color: "error.main", fontSize: 40, mb: 2 }}
                />
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Generation Failed
                </Typography>
                <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                  {error}
                </Typography>
                {stackRef.current[stackIndexRef.current].instruction && (
                  <Box sx={{ position: "relative", mb: 2 }}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ mx: 4 }}
                    >
                      &quot;
                      {stackRef.current[stackIndexRef.current].instruction}
                      &quot;
                    </Typography>
                  </Box>
                )}
                <Typography variant="body2" color="textSecondary">
                  Please try again or refine your instruction.
                </Typography>
                <IconButton
                  onClick={() =>
                    handleSendMessage(
                      stackRef.current[stackIndexRef.current].instruction,
                      null,
                      true
                    )
                  }
                  color="primary"
                  sx={{
                    mt: 2,
                    p: 1,
                    backgroundColor: "action.hover",
                    "&:hover": {
                      backgroundColor: "action.selected",
                    },
                  }}
                  size="small"
                >
                  <ReplayIcon />
                </IconButton>
              </Box>
            </Card>
            <ActionButtons />
          </Box>
        );
      } else if (params && params.mode === "new") {
        return (
          <Box sx={{ p: 3, maxWidth: 800, mx: "auto" }}>
            <Card
              elevation={0}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: "12px",
                backgroundColor: "background.card",
              }}
            >
              <Box>
                <AutomationIcon
                  sx={{ fontSize: 40, color: "primary.main", mb: 2 }}
                />
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Create New Automation
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mb: 1 }}
                >
                  Enter the instruction to describe what you want the automation
                  to do
                </Typography>
              </Box>
            </Card>
          </Box>
        );
      } else {
        return (
          <Box sx={{ p: 3 }}>
            <Card
              elevation={0}
              sx={{
                maxWidth: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: "none",
                borderRadius: "8px",
              }}
            >
              <AutomationEditCardContent
                params={params}
                onSave={handleAutomationSave}
                onChangeSummary={handleAutomationChangeSummary}
                onDelete={handleDelete}
                editable={true}
              />
            </Card>
            <ActionButtons />
          </Box>
        );
      }
    } else {
      return (
        <Box sx={{ p: 3 }}>
          <Card
            elevation={0}
            sx={{
              maxWidth: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "none",
              borderRadius: "8px",
            }}
          >
            <AutomationErrorCardContent
              type={errorType}
              payload={errorPayload}
            />
          </Card>
          <ActionButtons />
        </Box>
      );
    }
  };

  const ActionButtons = () => (
    <Box
      sx={{
        display: "flex",
        gap: 0.5,
        mt: 0.5,
      }}
    >
      <IconButton
        onClick={handleUndo}
        disabled={!canUndo}
        color="primary"
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: "action.selected",
          },
        }}
        size="small"
      >
        <UndoIcon fontSize="medium" />
      </IconButton>
      <IconButton
        onClick={handleRedo}
        disabled={!canRedo}
        color="primary"
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: "action.selected",
          },
        }}
        size="small"
      >
        <RedoIcon fontSize="medium" />
      </IconButton>
      <IconButton
        onClick={() =>
          handleSendMessage(
            stackRef.current[stackIndexRef.current].instruction,
            null,
            true
          )
        }
        disabled={!canReplay}
        color="primary"
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: "action.selected",
          },
        }}
        size="small"
      >
        <ReplayIcon fontSize="medium" />
      </IconButton>
      <IconButton
        onClick={() => {
          const instruction =
            stackRef.current[stackIndexRef.current].instruction;
          navigator.clipboard.writeText(instruction);
        }}
        disabled={!canReplay} // Disable button if `canReplay` is false
        color="primary"
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: "action.selected",
          },
        }}
        size="small"
      >
        <ContentCopyIcon fontSize="medium" />
      </IconButton>
    </Box>
  );

  return (
    <Box
      sx={{
        position: "fixed",
        top: {
          xs: 56,
          sm: 64,
        },
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.default",
        zIndex: 1,
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box sx={{ flex: 1, overflow: "auto" }}>{renderContent()}</Box>
          <Box
            sx={{
              borderTop: 1,
              borderColor: "divider",
              bgcolor: "background.paper",
            }}
          >
            <InstructionInput
              placeholder={
                params.mode === "new"
                  ? "Enter your initial instruction"
                  : "Enter your instruction to update the automation"
              }
              sendName="Send"
              onSendText={(text) => handleSendMessage(text, null, false)}
              onSendAudio={(audio) => handleSendMessage(null, audio, false)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default OverviewConfigView;
