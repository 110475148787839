import React, { useEffect, useState } from "react";
import { Card, CardHeader, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AutomationErrorCardContent from "../automation/automation-error-card-content";

const AutomationErrorChatCard = ({ payload }) => {
  const theme = useTheme();
  const [errorType, setErrorType] = useState("");
  const [errorPayload, setErrorPayload] = useState(null);

  useEffect(() => {
    setErrorType(payload.data.type);
    setErrorPayload(JSON.parse(payload.data.payload));
  }, [payload]);

  return (
    <Card
      elevation={0}
      sx={{
        maxWidth: "100%",
        backgroundColor: theme.palette.background.card,
        fontFamily: "Roboto, sans-serif",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "8px",
      }}
    >
      <CardHeader
        title={<Typography variant="h6">Error</Typography>}
        sx={{
          backgroundColor: theme.palette.background.l4,
          color: theme.palette.text.black,
        }}
      />
      <AutomationErrorCardContent
        type={errorType}
        payload={errorPayload}
      ></AutomationErrorCardContent>
    </Card>
  );
};

export default AutomationErrorChatCard;
