import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { listIntegrations } from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import Grid from "@mui/material/Grid";
import IntegrationsFilter from "./integrations-filter";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PhonelinkSetupTwoToneIcon from "@mui/icons-material/PhonelinkSetupTwoTone";
import ScheduleIcon from "@mui/icons-material/Schedule";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DeviceHubTwoToneIcon from "@mui/icons-material/DeviceHubTwoTone";
import { Bot } from "lucide-react";
import LoadingSpinner from "../common/loading";

const IntegrationListView = ({ showFilter = false, setShowFilter }) => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [integrations, setIntegrations] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortBy, setSortBy] = useState("alphabet");
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setShowFilter(false);
    setFilterText("");
    handleLoadIntegrations();
  }, []);

  const handleLoadIntegrations = async () => {
    setLoading(true);
    const resp = await listIntegrations(apiCall, userInfo.activeScope.id);

    if (resp) {
      setIntegrations(resp.integrations);
    } else {
      errorToast("Error loading integrations!");
    }
    setLoading(false);
  };

  const handleCardClick = (integration) => {
    const integrationId = integration.integration;
    history.push(`/integration?id=${integrationId}`);
  };

  const sortedIntegrations = integrations
    .filter((a) =>
      a.integration.toLowerCase().includes(filterText.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "alphabet") {
        const aName = a.integration.toLowerCase();
        const bName = b.integration.toLowerCase();

        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName); //sort alphabetically
      }
    });

  const getLogo = (name) => {
    const commonIconStyle = {
      width: "36px", // Using fixed pixel values instead of percentages
      height: "36px", // This ensures consistent size across all icons
    };

    name = name.trim().toLowerCase();

    switch (name) {
      case "automation":
        return <AutoModeIcon sx={commonIconStyle} />;
      case "app & dashboard":
        return <DashboardIcon sx={commonIconStyle} />;
      case "chat":
        return (
          <Box
            sx={{
              width: "36px",
              height: "36px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Bot style={commonIconStyle} />
          </Box>
        );
      case "devices":
        return <LightbulbOutlinedIcon sx={commonIconStyle} />;
      case "phone":
        return <PhonelinkSetupTwoToneIcon sx={commonIconStyle} />;
      case "time & schedule":
        return <ScheduleIcon sx={commonIconStyle} />;
      case "weather":
        return <WbSunnyOutlinedIcon sx={commonIconStyle} />;
      default:
        return <DeviceHubTwoToneIcon sx={commonIconStyle} />;
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ height: "100%" }}>
          <Box sx={{ p: 2 }}>
            {showFilter && (
              <IntegrationsFilter
                sortBy={sortBy}
                setSortBy={setSortBy}
                setFilterText={setFilterText}
                setShowFilter={setShowFilter}
              />
            )}
            <Grid container spacing={2}>
              {sortedIntegrations.map((integration) => (
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  lg={2}
                  key={integration.integration}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      height: "100%",
                      padding: 1.5,
                      cursor: "pointer",
                      backgroundColor: integration.installed
                        ? theme.palette.background.paper
                        : theme.palette.background.disabled,
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: theme.shadows[4],
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                    onClick={() => handleCardClick(integration)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mb: 2,
                          mt: 2,
                          flexGrow: 0,
                        }}
                      >
                        {getLogo(integration.name)}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          flexGrow: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 0.5,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              fontWeight: "medium",
                              maxWidth: "calc(100% - 24px)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {integration.name}
                          </Typography>
                          {integration.installed && (
                            <Box sx={{ ml: 0.5, flexShrink: 0 }}>
                              <DownloadDoneIcon
                                color="success"
                                sx={{ fontSize: 20 }}
                              />
                            </Box>
                          )}
                        </Box>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="center"
                          sx={{
                            fontSize: "0.75rem",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {integration.org}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default IntegrationListView;
