import React from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Card,
  Grid,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

const About = () => {
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const items = [
    { label: "Terms of Use", url: "https://app.mhome.ai/terms-of-use" },
    { label: "Privacy Policy", url: "https://app.mhome.ai/privacy-policy" },
    { label: "Licenses", url: "https://app.mhome.ai/licenses" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        p: { xs: 2, md: 3 },
        // bgcolor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
          gap: 1,
        }}
      >
        {isSmallScreen && (
          <IconButton
            onClick={() =>
              isSmallScreen ? history.goBack() : history.replace("/setting")
            }
            sx={{ color: theme.palette.text.primary }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h6" fontWeight="500">
          About
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Card
              component="a"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                p: 2.5,
                backgroundColor: theme.palette.background.paper,
                textDecoration: "none",
                color: theme.palette.text.primary,
                cursor: "pointer",
                borderRadius: 4,
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: "none",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  transform: "translateX(8px)",
                  boxShadow: `0 4px 12px ${theme.palette.action.hover}`,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                  }}
                >
                  {item.label}
                </Typography>
                <ChevronRightIcon
                  sx={{
                    color: theme.palette.text.secondary,
                    transition: "transform 0.2s ease-in-out",
                    ".MuiCard-root:hover &": {
                      transform: "translateX(4px)",
                    },
                  }}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default About;
