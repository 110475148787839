// ProviderDetailDialog.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DisconnectIcon from "@mui/icons-material/LinkOff";
import AddIcon from "@mui/icons-material/Add";

export default function ProviderDetailDialog({
  open,
  provider,
  onClose,
  onDisconnect,
  onNewConnect,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!provider) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          height: isMobile ? "100%" : "auto",
          maxHeight: "100%",
          borderRadius: isMobile ? 0 : 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
          Provider Details
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogContent sx={{ p: 2 }}>
        {/* Provider Name */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          {provider.name}
        </Typography>

        {/* Connection Actions */}
        <Box sx={{ mb: 3 }}>
          {provider.multiConnect ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => onNewConnect(provider)}
              sx={{ mb: 2 }}
            >
              New Connection
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              color={provider.installed ? "error" : "primary"}
              onClick={() =>
                provider.installed
                  ? onDisconnect(provider)
                  : onNewConnect(provider)
              }
              startIcon={provider.installed ? <DisconnectIcon /> : <AddIcon />}
            >
              {provider.installed ? "Disconnect" : "Connect"}
            </Button>
          )}
        </Box>

        {/* Connections List (if multiConnect and has connections) */}
        {provider.multiConnect && provider.connects.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Active Connections
            </Typography>
            <Box
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
                mb: 3,
              }}
            >
              <List disablePadding>
                {provider.connects.map((connection, index) => (
                  <React.Fragment key={connection.id}>
                    {index > 0 && <Divider />}
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="disconnect"
                          onClick={() => onDisconnect(provider, connection.id)}
                          sx={{ color: theme.palette.error.main }}
                          size="small"
                        >
                          <DisconnectIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={connection.name}
                        primaryTypographyProps={{
                          variant: "body2",
                        }}
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </>
        )}

        {/* Description Section */}
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          About
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {provider.description || "No description available"}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
