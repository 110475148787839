import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import { customFormat } from "../common/custom-format";
import { useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const NotificationContent = ({
  setNotification,
  combinedItems,
  onClose,
  onClearNotifications,
  onDialogClick,
  onNotificationClick,
  calculateRemainingTime,
  currentTime,
  customFormat,
  theme,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const safeSetNotification = useCallback(
    (item) => {
      if (typeof setNotification === "function") {
        setNotification(item);
      } else {
        console.warn("setNotification is not a valid function");
      }
    },
    [setNotification]
  );

  return (
    <Box p={2} width={isSmallScreen ? "100%" : 400}>
      {!isSmallScreen && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            Notifications
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={onClearNotifications}
        sx={{ mb: 2 }}
      >
        Clear All
      </Button>
      <List>
        {combinedItems.map((item, index) => {
          const remainingTime = item.timeout
            ? calculateRemainingTime(item.createdAt, item.timeout)
            : null;

          return (
            <React.Fragment key={index}>
              <ListItem
                button
                sx={{
                  backgroundColor: item.dialogId
                    ? theme.palette.background.dialog
                    : theme.palette.background.notification,
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <Typography
                        variant="caption"
                        sx={{ display: "block" }}
                        component="div"
                      >
                        {customFormat(dayjs(item.timestamp), currentTime)}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          safeSetNotification(item);
                        }}
                      >
                        <Typography variant="h6" component="div">
                          {item.title || "Action Required"}
                        </Typography>
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            item.dialogId
                              ? onDialogClick(item)
                              : onNotificationClick(item);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  }
                />
                {remainingTime !== null && (
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate"
                      value={(remainingTime / (item.timeout / 1000)) * 100}
                      size={40}
                      thickness={4}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {`${remainingTime}s`}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};

const NotificationDialogList = ({
  notifications,
  setNotification,
  dialogs,
  onClose,
  onNotificationClick,
  onDialogClick,
  onDialogTimeout,
  open,
  onClearNotifications,
}) => {
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const now = dayjs();

    let dd = null;
    for (let i = 0; i < dialogs.length; i++) {
      const d = dialogs[i];
      if (dayjs(d.createdAt).add(d.timeout, "millisecond").isBefore(now)) {
        dd = d;
        break;
      }
    }
    if (dd) {
      onDialogTimeout(dd);
    }
  }, [dialogs]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  const calculateRemainingTime = (timeout) => {
    const now = currentTime;
    const endTime = dayjs(timeout);
    return endTime.diff(now, "second");
  };

  // Combine and sort notifications and dialogs by timestamp
  const combinedItems = [...notifications, ...dialogs].sort((a, b) => {
    const t1 = a.dialogId ? a.createdAt : a.timestamp;
    const t2 = b.dialogId ? b.createdAt : b.timestamp;
    return t2 - t1;
  });

  return isSmallScreen ? (
    <>
      {open && (
        <Box
          role="presentation"
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <NotificationContent
            setNotification={setNotification}
            combinedItems={combinedItems}
            onClose={onClose}
            onClearNotifications={onClearNotifications}
            onDialogClick={onDialogClick}
            onNotificationClick={onNotificationClick}
            calculateRemainingTime={calculateRemainingTime}
            currentTime={currentTime}
            customFormat={customFormat}
            theme={theme}
          />
        </Box>
      )}
    </>
  ) : (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      // BackdropProps={{
      //   style: { display: "none" },
      // }}
    >
      <NotificationContent
        setNotification={setNotification}
        combinedItems={combinedItems}
        onClose={onClose}
        onClearNotifications={onClearNotifications}
        onDialogClick={onDialogClick}
        onNotificationClick={onNotificationClick}
        calculateRemainingTime={calculateRemainingTime}
        currentTime={currentTime}
        customFormat={customFormat}
        theme={theme}
      />
    </Drawer>
  );
};

export default NotificationDialogList;
