import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import dayjs from "dayjs";
import useApiCall from "../common/api-call";
import { userInfoState } from "../global-state";
import { useRecoilState } from "recoil";

import { useLocation } from "react-router-dom";
const today = new Date();

const AutomationHistoryStatistics = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [filteredData, setFilteredData] = useState({});
  const { apiCall } = useApiCall();
  const [userInfo] = useRecoilState(userInfoState);

  const startDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0
  ).getTime();

  const endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    23,
    59,
    59
  ).getTime();

  const fetchStatisticsData = async () => {
    const resp = await apiCall(
      `/statistics/history/get?scope=${userInfo.activeScope.id}&automation=${id}&start=${startDate}&end=${endDate}`,
      "GET"
    );

    if (resp && !resp.error) {
      setFilteredData(resp);
    } else {
      setFilteredData({
        hourAutomationHistoryStatistics: [],
      });
    }
  };

  useEffect(() => {
    fetchStatisticsData();
  }, []);

  const hourData = Array.from({ length: 24 }, (_, i) => ({
    hour: i,
    successCount: 0,
    failCount: 0,
    runnedCount: 0,
  }));

  filteredData.hourAutomationHistoryStatistics?.forEach((item) => {
    const hourIndex = item.timeHour % 24;
    hourData[hourIndex].successCount += item.successCount;
    hourData[hourIndex].failCount += item.failCount;
    hourData[hourIndex].runnedCount += item.successCount + item.failCount;
  });

  const totalRunCount = filteredData.hourAutomationHistoryStatistics?.reduce(
    (sum, item) => sum + item.successCount + item.failCount,
    0
  );
  const totalSuccessCount =
    filteredData.hourAutomationHistoryStatistics?.reduce(
      (sum, item) => sum + item.successCount,
      0
    );
  const totalFailCount = filteredData.hourAutomationHistoryStatistics?.reduce(
    (sum, item) => sum + item.failCount,
    0
  );

  const successPercentage = totalRunCount
    ? ((totalSuccessCount / totalRunCount) * 100).toFixed(2)
    : 0;

  const failPercentage = totalRunCount
    ? ((totalFailCount / totalRunCount) * 100).toFixed(2)
    : 0;

  useEffect(() => {
    const chartDom = document.getElementById("history-chart");
    const myChart = echarts.init(chartDom);

    const option = {
      title: {
        text: "Today's Data Statistics",
        left: "center",

        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Runned Count", "Success Count", "Failed Count"],
        top: "12%",
      },
      grid: {
        top: "30%",
        left: "10%",
        right: "10%",
        bottom: "10%",
      },
      xAxis: {
        type: "category",
        data: hourData.map((item) => item.hour),
        axisLabel: {
          interval: 3,
          formatter: (value) => `${value}h`,
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: filteredData
          ? Math.max(
              filteredData.hourAutomationHistoryStatistics?.map(
                (item) => item.successCount + item.failCount
              )
            )
          : 100,
        axisLabel: {
          formatter: (value) => value.toLocaleString(),
        },

        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
      },
      series: [
        {
          name: "Runned Count",
          type: "line",
          data: hourData?.map((item) => item.successCount || item.failCount),
          animation: true,
        },
        {
          name: "Success Count",
          type: "line",
          data: hourData?.map((item) => item.successCount),
          animation: true,
        },
        {
          name: "Failed Count",
          type: "line",
          data: hourData?.map((item) => item.failCount),
          animation: true,
        },
      ],
    };

    myChart.setOption(option);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      myChart.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData]);

  useEffect(() => {
    const pieChartDom = document.getElementById("pie-chart");
    const pieChart = echarts.init(pieChartDom);

    const pieOption = {
      title: {
        text: "Success vs Failed Ratio",
        left: "center",
        top: "5%",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      series: [
        {
          name: "Ratio",
          type: "pie",
          radius: ["40%", "70%"],
          data: [
            {
              value: totalSuccessCount,
              name: "Success",
              itemStyle: { color: "#4caf50" },
            },
            {
              value: totalFailCount,
              name: "Failed",
              itemStyle: { color: "#ff9800" },
            },
          ],
          animation: true,
          label: {
            show: false,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      graphic: [
        {
          type: "text",
          left: "center",
          top: "47%",
          style: {
            text: `Success: ${successPercentage}%`,
            textAlign: "center",
            fontSize: 14,
            fill: "#4caf50",
          },
        },
        {
          type: "text",
          left: "center",
          top: "54%",
          style: {
            text: `Failed: ${failPercentage}%`,
            textAlign: "center",
            fontSize: 14,
            fill: "#ff9800",
          },
        },
      ],
    };

    pieChart.setOption(pieOption);

    const handleResize = () => {
      pieChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      pieChart.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [totalSuccessCount, totalFailCount]);

  return (
    <Box sx={{ flexGrow: 1, height: "100%", m: 2, pb: "80px" }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1.5,
          mb: 2,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            flex: {
              xs: "1 1 calc(50% - 16px)",
              sm: "1 1 calc(33.333% - 16px)",
            },
            maxWidth: "300px",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          <Box sx={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
            Total Runned Count :
          </Box>
          <Box
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#2196f3",
            }}
          >
            {totalRunCount}
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            flex: {
              xs: "1 1 calc(50% - 16px)",
              sm: "1 1 calc(33.333% - 16px)",
            },
            maxWidth: "300px",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          <Box sx={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
            Total Success Count :
          </Box>
          <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#4caf50" }}>
            {totalSuccessCount}
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            flex: {
              xs: "1 1 calc(50% - 16px)",
              sm: "1 1 calc(33.333% - 16px)",
            },
            maxWidth: "300px",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          <Box sx={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
            Total Fail Count :
          </Box>
          <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#ff9800" }}>
            {totalFailCount}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: { xs: "300px", sm: "400px" } }}>
        <Box id="history-chart" style={{ width: "100%", height: "100%" }} />
      </Box>
      <Box sx={{ width: "100%", height: { xs: "300px", sm: "400px" }, mt: 2 }}>
        <Box id="pie-chart" style={{ width: "100%", height: "100%" }} />
      </Box>
    </Box>
  );
};

export default AutomationHistoryStatistics;
