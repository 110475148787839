import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

const EntityDetailsDialog = ({ open, meta, entity, onClose }) => {
  const [selectedTwinTags, setSelectedTwinTags] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const formatCapability = (capability) => {
    return meta.capabilityNames[capability] || capability;
  };

  const getIntegrationName = (integration) => {
    return meta.integrationNames[integration] || integration;
  };

  const getTwinName = (twinTag) => {
    return meta.twinNames[twinTag] || twinTag;
  };

  const groupedCapabilities = entity?.capabilities?.reduce(
    (acc, capability) => {
      let type = capability.split(".")[0];
      if (type !== "command") {
        type = "data";
      }
      if (!acc[type]) acc[type] = [];
      acc[type].push(capability);
      return acc;
    },
    {}
  );

  const handleTwinTagToggle = (tag) => {
    setSelectedTwinTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const handleAddDashboard = () => {
    // if (onAddDashboard) {
    //   onAddDashboard({
    //     entity,
    //     selectedTwinTags
    //   });
    // }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          height: isMobile ? "100%" : "auto",
          maxHeight: "100%",
          borderRadius: isMobile ? 0 : 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          p: 1.5,
          display: "flex",
          alignItems: "center",
          gap: 1,
          minHeight: 48,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
          Device Details
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogContent sx={{ p: 2 }}>
        {/* Device Name */}
        <Typography variant="h6" gutterBottom>
          {entity?.name}
        </Typography>

        {/* Integration Type */}
        <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
          {getIntegrationName(entity?.integration)}
        </Typography>

        {/* Capabilities Section */}
        {/* State Capabilities */}
        {groupedCapabilities?.data && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Data
            </Typography>
            <Stack direction="row" spacing={0.5} flexWrap="wrap">
              {groupedCapabilities.data.map((capability) => (
                <Chip
                  key={capability}
                  label={formatCapability(capability)}
                  size="small"
                  sx={{
                    mb: 0.5,
                    backgroundColor: theme.palette.primary.main + "1A",
                    color: theme.palette.primary.main,
                  }}
                />
              ))}
            </Stack>
          </Box>
        )}

        {/* Command Capabilities */}
        {groupedCapabilities?.command && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Commands
            </Typography>
            <Stack direction="row" spacing={0.5} flexWrap="wrap">
              {groupedCapabilities.command.map((capability) => (
                <Chip
                  key={capability}
                  label={formatCapability(capability)}
                  size="small"
                  sx={{
                    mb: 0.5,
                    backgroundColor: theme.palette.success.main + "1A",
                    color: theme.palette.success.main,
                  }}
                />
              ))}
            </Stack>
          </Box>
        )}

        {/* Twin Tags Section */}
        {process.env.NODE_ENV === "development" &&
          entity?.twinTags &&
          entity.twinTags.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2">Card Content</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddDashboard}
                  disabled={selectedTwinTags.length === 0}
                >
                  Add Dashboard
                </Button>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  p: 1,
                }}
              >
                {entity.twinTags.map((tag) => (
                  <Box
                    key={tag}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Checkbox
                      checked={selectedTwinTags.includes(tag)}
                      onChange={() => handleTwinTagToggle(tag)}
                      size="small"
                    />
                    <Typography variant="body2">{getTwinName(tag)}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </DialogContent>
    </Dialog>
  );
};

export default EntityDetailsDialog;
