import React, { useEffect } from "react";
import useApiCall from "./common/api-call";
import { useToast } from "./common/toast";

const OAuthCallback = () => {
  const { apiCall } = useApiCall();
  const { successToast, warningToast, errorToast } = useToast();

  useEffect(() => {
    const handleOAuthResponse = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const paramsObject = {};
      for (const [key, value] of queryParams.entries()) {
        paramsObject[key] = value;
      }

      const sessionId = paramsObject.state;
      const sessionKey = `oauth_session_${sessionId}`;

      // Get data from localStorage instead of sessionStorage
      const sessionData = JSON.parse(localStorage.getItem(sessionKey) || "{}");

      if (!sessionData.timestamp) {
        console.error("Invalid or expired OAuth session");
        return;
      }

      try {
        const req = {
          oauthCode: paramsObject.code,
          oauthScope: paramsObject.scope,
          oauthState: paramsObject.state,
          integration: sessionData.integration,
          integrationConnect: sessionData.integrationConnect,
          scopeId: sessionData.scopeId,
        };

        const response = await apiCall(
          "/integration/oauth/code/callback",
          "POST",
          req,
          "user,dev"
        );

        localStorage.removeItem(sessionKey);

        if (window.opener) {
          window.opener.postMessage(
            {
              type: "OAUTH_SUCCESS",
              sessionId: sessionId,
            },
            "*"
          );
          window.close();
        } else {
          successToast("Connected!");
          window.location.href = sessionData.originUrl;
        }
      } catch (error) {
        if (window.opener) {
          window.opener.postMessage(
            {
              type: "OAUTH_ERROR",
              sessionId: sessionId,
            },
            "*"
          );
          window.close();
        } else {
          successToast("Error!");
          window.location.href = sessionData.originUrl;
        }
      }
    };

    handleOAuthResponse();
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        fontFamily: "sans-serif",
      }}
    >
      Processing authentication...
    </div>
  );
};

export default OAuthCallback;
