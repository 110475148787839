// GroupListView.jsx
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GroupFilter from "./group-filter";
import { useTheme } from "@mui/material/styles";
import EditGroupDialog from "./edit-group-dialog";
import { deleteGroup, listGroups, saveGroup } from "./integration-service";
import { useToast } from "../common/toast";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";

const CreateGroupCard = ({ theme, onCreateClick }) => {
  return (
    <Card
      elevation={0}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 90,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        transition: "all 0.2s ease-in-out",
        WebkitTapHighlightColor: "transparent",
        touchAction: "manipulation",
        userSelect: "none",
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        cursor: "pointer",
      }}
      onClick={onCreateClick}
    >
      <CardContent sx={{ p: "12px !important", textAlign: "center" }}>
        <AddIcon sx={{ mb: 0.5 }} />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.2,
          }}
        >
          Create Group
        </Typography>
      </CardContent>
    </Card>
  );
};

const GroupCard = ({ group, index, theme, onEditClick }) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: 90,
        backgroundColor: theme.palette.background.paper,
        border: "none",
        transition: "all 0.2s ease-in-out",
        WebkitTapHighlightColor: "transparent",
        touchAction: "manipulation",
        userSelect: "none",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:active": {
          backgroundColor: theme.palette.action.selected,
        },
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: theme.palette.background.paper,
          },
        },
        cursor: "pointer",
      }}
      onClick={() => onEditClick(group)}
    >
      <CardContent
        sx={{
          p: "12px !important",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            textAlign: "center",
            lineHeight: 1.2,
          }}
        >
          {group.name || `Group ${index + 1}`}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          noWrap
          sx={{
            mt: 0.5,
            textAlign: "center",
          }}
        >
          {group.entities?.length || 0} entities
        </Typography>
      </CardContent>
    </Card>
  );
};

const GroupListView = ({ integrationData, showFilter, onGroupChanged }) => {
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [groupFilterText, setGroupFilterText] = useState("");
  const [groupSortBy, setGroupSortBy] = useState("alphabet");
  const { successToast, errorToast } = useToast();
  const { apiCall } = useApiCall();
  const [userInfo] = useRecoilState(userInfoState);
  const theme = useTheme();
  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get("id");

  const handleLoadGroups = async () => {
    const resp = await listGroups(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      onGroupChanged(resp.groups);
    } else {
      errorToast("Error!");
    }
  };

  const handleEditGroup = (group = null) => {
    setSelectedGroup(group);
    setNewGroup(!group);
    setGroupName(group ? group.name : "");
    setSelectedEntities(
      group ? group.entities.map((e) => `${e.integration}-${e.id}`) || [] : []
    );
    setGroupDialogOpen(true);
  };

  const handleToggleEntity = (entityId) => {
    setSelectedEntities((prev) =>
      prev.includes(entityId)
        ? prev.filter((id) => id !== entityId)
        : [...prev, entityId]
    );
  };

  const handleSaveGroup = async (updatedSelection) => {
    const group = {
      scopeId: userInfo.activeScope.id,
      groupId: null,
      name: groupName,
      integration: integrationId,
      entities: [],
    };
    if (selectedGroup) {
      group.groupId = selectedGroup.groupId;
    }
    updatedSelection.forEach((s) => {
      const integrationId = s.substring(0, s.indexOf("-"));
      const entityId = s.substring(s.indexOf("-") + 1);
      group.entities.push({ id: entityId, integration: integrationId });
    });

    if (!group.name) {
      errorToast("Name is required!");
      return;
    }
    if (group.entities.length === 0) {
      errorToast("No group members are selected!");
      return;
    }

    const resp = await saveGroup(apiCall, group);
    if (resp) {
      if (resp.success) {
        successToast("Saved");
        handleLoadGroups();
        setGroupDialogOpen(false);
        setGroupName("");
        setSelectedEntities([]);
      } else {
        if (resp.error === "BAD_REQUEST")
          errorToast("Error! Use a different group name.");
      }
    } else {
      errorToast("Error!");
    }
  };

  const handleDeleteGroup = async () => {
    if (!selectedGroup.groupId) {
      errorToast("Error!");
      return;
    }
    const req = {
      scopeId: userInfo.activeScope.id,
      groupId: selectedGroup.groupId,
    };
    const resp = await deleteGroup(apiCall, req);
    if (resp) {
      successToast("Deleted");
      handleLoadGroups();
      setGroupDialogOpen(false);
      setGroupName("");
      setSelectedEntities([]);
    } else {
      errorToast("Error!");
    }
  };

  const sortedGroups = integrationData.groups
    .filter((group) =>
      group.name.toLowerCase().includes(groupFilterText.toLowerCase())
    )
    .sort((a, b) => {
      if (groupSortBy === "alphabet") {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName);
      }
      return 0;
    });

  return (
    <Box>
      {showFilter && (
        <GroupFilter
          sortBy={groupSortBy}
          setSortBy={setGroupSortBy}
          setFilterText={setGroupFilterText}
        />
      )}
      <Grid container spacing={1.5}>
        <Grid item xs={6} sm={4} md={2} lg={2}>
          <CreateGroupCard
            theme={theme}
            onCreateClick={() => handleEditGroup()}
          />
        </Grid>
        {sortedGroups.map((group, index) => (
          <Grid item xs={6} sm={4} md={2} lg={2} key={index}>
            <GroupCard
              group={group}
              index={index}
              theme={theme}
              onEditClick={handleEditGroup}
            />
          </Grid>
        ))}
      </Grid>
      <EditGroupDialog
        open={groupDialogOpen}
        onClose={() => setGroupDialogOpen(false)}
        onSave={handleSaveGroup}
        onDelete={handleDeleteGroup}
        groupName={groupName}
        setGroupName={setGroupName}
        entities={integrationData.entities}
        selectedEntities={selectedEntities}
        onToggleEntity={handleToggleEntity}
        isNewGroup={newGroup}
      />
    </Box>
  );
};

export default GroupListView;
