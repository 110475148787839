export const getHistory = async (
  apiCall,
  scopeId,
  automationId,
  size,
  token,
  filterTimestamp
) => {
  const encodedToken = token ? encodeURIComponent(token) : null;
  return await apiCall(
    `/automation/history/get?scope=${scopeId}&id=${automationId}&size=${size}${
      encodedToken ? `&nextToken=${encodedToken}` : ""
    }${filterTimestamp ? `&timestamp=${filterTimestamp}` : ""}`,
    "GET"
  );
};

export const getHistoryList = async (
  apiCall,
  scopeId,
  size,
  token,
  filterTimestamp
) => {
  const encodedToken = token ? encodeURIComponent(token) : null;
  return await apiCall(
    `/automation/history/list?scope=${scopeId}&size=${size}${
      encodedToken ? `&nextToken=${encodedToken}` : ""
    }${filterTimestamp ? `&timestamp=${filterTimestamp}` : ""}`,
    "GET"
  );
};
