// EntityListView.jsx
import React, { useState } from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import EntityFilter from "./entity-filter";
import { useTheme } from "@mui/material/styles";
import EntityDetailsDialog from "./entity-dialog";

const EntityCard = ({ meta, entity, theme, onSelect }) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: 90,
        border: "none",
        backgroundColor: theme.palette.background.paper,
        transition: "all 0.2s ease-in-out",
        WebkitTapHighlightColor: "transparent",
        touchAction: "manipulation",
        userSelect: "none",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:active": {
          backgroundColor: theme.palette.action.selected,
        },
        // Mobile-specific styles
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: theme.palette.background.paper,
          },
        },
        cursor: "pointer",
      }}
      onClick={() => onSelect(entity)}
    >
      <CardContent
        sx={{
          p: "12px !important",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.2,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "2.4em",
          }}
        >
          {entity.name}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          noWrap
          sx={{ mt: 0.5 }}
        >
          {meta.integrationNames[entity.integration] || entity.integration}
        </Typography>
      </CardContent>
    </Card>
  );
};

const EntityListView = ({ meta, entities, showFilter }) => {
  const [entityFilterText, setEntityFilterText] = useState(""); // Changed to useState
  const [entitySortBy, setEntitySortBy] = useState("alphabet");
  const [selectedEntity, setSelectedEntity] = useState(null);
  const theme = useTheme();

  // Moved the filtering and sorting logic into a useMemo to optimize performance
  const sortedEntities = entities
    .filter((entity) =>
      entity.name.toLowerCase().includes(entityFilterText.toLowerCase())
    )
    .sort((a, b) => {
      if (entitySortBy === "alphabet") {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName);
      }
      return 0;
    });

  return (
    <Box>
      {showFilter && (
        <EntityFilter
          sortBy={entitySortBy}
          setSortBy={setEntitySortBy}
          setFilterText={setEntityFilterText} // Updated to use the new setState function
        />
      )}
      <Grid container spacing={1.5}>
        {sortedEntities.map((entity) => (
          <Grid item xs={6} sm={4} md={2} lg={2} key={entity.id}>
            <EntityCard
              meta={meta}
              entity={entity}
              theme={theme}
              onSelect={setSelectedEntity}
            />
          </Grid>
        ))}
      </Grid>
      <EntityDetailsDialog
        meta={meta}
        open={Boolean(selectedEntity)}
        entity={selectedEntity}
        onClose={() => setSelectedEntity(null)}
      />
    </Box>
  );
};

export default EntityListView;
