import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  Stack,
  IconButton,
  useMediaQuery,
  Paper,
  Grid,
  Collapse,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentIcon from "@mui/icons-material/Payment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useHistory } from "react-router-dom";
import { getCredits, getPayments, startCheckout } from "./billing-service";
import useApiCall from "../common/api-call";
import LoadingSpinner from "../common/loading";
import { customFormat } from "../common/custom-format";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { settingBackPathState } from "../global-state";
import ReceiptIcon from "@mui/icons-material/Receipt";

const Billing = () => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const buttonRef = useRef(null);
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState({});
  const [payments, setPayments] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [settingBackPath, setSettingBackPath] =
    useRecoilState(settingBackPathState);

  useEffect(() => {
    handleGetCredits();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGetCredits = async () => {
    setLoading(true);
    const resp1 = await getCredits(apiCall);
    const resp2 = await getPayments(apiCall);
    console.log(resp1);
    console.log(resp2);
    setCredits(resp1 || {});
    setPayments(resp2?.payments || []);

    setLoading(false);
  };

  const getPlanName = (plan) => {
    if (plan === "E") {
      return "Internal";
    }
    if (plan === "T") {
      return "Basic Free (Early Adopter)";
    }
    if (plan === "V0") {
      return "Basic Free";
    }
    if (plan === "V1") {
      return "Plus";
    }
    return "Unknown";
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="column"
          height="100%"
          p={2}
          width="100%"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box
              position="static"
              sx={{
                display: "flex",
                alignItems: "center",
                justfyContent: "flex-start",
                mb: 2,
              }}
            >
              {isSmallScreen && (
                <IconButton
                  color="inherit"
                  edge="start"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    if (settingBackPath) {
                      history.replace(settingBackPath);
                      setSettingBackPath("");
                    } else {
                      history.replace("/setting");
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}

              <Typography variant="h6" style={{ textAlign: "center" }}>
                Billing
              </Typography>
            </Box>
            <Box>
              <IconButton
                color="inherit"
                edge="start"
                sx={{ mr: 1 }}
                onClick={() => {
                  history.push("/setting#billing-records");
                }}
              >
                <ReceiptIcon />
              </IconButton>
            </Box>
          </Box>

          <Container maxWidth="lg" sx={{ py: isSmallScreen ? 1 : 3 }}>
            <Stack spacing={4} alignItems="center">
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  mx: "auto",
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: "none",
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  <Stack spacing={1}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <AutorenewIcon
                          sx={{ mr: 1, fontSize: "1.2rem" }}
                          color="primary"
                        />
                        <Typography sx={{ fontSize: "0.95rem" }}>
                          Subscription Plan:
                        </Typography>
                      </Box>
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        {getPlanName(credits?.tier)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      <Box display="flex" alignItems="center">
                        <AutorenewIcon
                          sx={{ mr: 1, fontSize: "1.2rem" }}
                          color="primary"
                        />
                        <Typography sx={{ fontSize: "0.95rem" }}>
                          Next Refresh Date:
                        </Typography>
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "1rem", ml: 3.5 }}
                      >
                        {customFormat(
                          dayjs(credits?.nextRefreshTimestamp),
                          currentTime
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <AutorenewIcon
                          sx={{ mr: 1, fontSize: "1.2rem" }}
                          color="primary"
                        />
                        <Typography sx={{ fontSize: "0.95rem" }}>
                          Max Automation:
                        </Typography>
                      </Box>
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        {credits?.automationLimit === -1
                          ? "Unlimited"
                          : credits?.automationLimit || 0}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <ShoppingCartIcon
                          sx={{ mr: 1, fontSize: "1.2rem" }}
                          color="primary"
                        />
                        <Typography sx={{ fontSize: "0.95rem" }}>
                          Permanent AI Credits:
                        </Typography>
                      </Box>
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        {credits?.onDemandBalance || 0}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <AutorenewIcon
                          sx={{ mr: 1, fontSize: "1.2rem" }}
                          color="primary"
                        />
                        <Typography sx={{ fontSize: "0.95rem" }}>
                          Monthly AI Credits:
                        </Typography>
                      </Box>
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        {credits?.refreshBalance || 0}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
              <Box textAlign="center">
                <Typography variant="h3" gutterBottom>
                  Add Credits
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Choose a package to add credits to your account
                </Typography>
              </Box>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  mx: "auto",
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Stack spacing={3}>
                    <Box textAlign="center">
                      <Typography variant="h5" gutterBottom>
                        500 Credits
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(0,0,0,0.5)",
                            textDecoration: "line-through",
                            mr: 2,
                          }}
                        >
                          $5.00
                        </Typography>
                        <Typography
                          variant="h4"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          $2.00
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        60% OFF
                      </Typography>
                    </Box>

                    <Divider />

                    <Box>
                      <Button
                        ref={buttonRef}
                        variant="contained"
                        fullWidth
                        size="large"
                        startIcon={<PaymentIcon />}
                        onClick={async () => {
                          try {
                            const response = await startCheckout(
                              apiCall,
                              "c500"
                            );
                            if (response) {
                              window.location.href = response.url;
                            }
                          } catch (error) {
                            console.error(
                              "Error creating checkout session:",
                              error
                            );
                          }
                        }}
                      >
                        Purchase
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>

              <Box textAlign="center">
                <Typography variant="h3" gutterBottom>
                  Payment History
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Your transaction records
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                {payments.map((payment, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      mx: "auto",
                      mb: 1,
                      borderRadius: 2,
                      border: `1px solid ${theme.palette.divider}`,
                      boxShadow: "none",
                    }}
                  >
                    <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                            {payment.description}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              color: "primary.main",
                              fontWeight: "bold",
                              fontSize: "1rem",
                            }}
                          >
                            ${(payment.amount / 100).toFixed(2)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              fontSize: "0.75rem",
                            }}
                          >
                            {customFormat(
                              dayjs(payment.timestamp),
                              currentTime
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>

              {payments.length === 0 && (
                <Card sx={{ maxWidth: 400, mx: "auto", width: "100%" }}>
                  <CardContent>
                    <Typography color="text.secondary" textAlign="center">
                      No payment history available
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Billing;
