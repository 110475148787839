import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Chip,
  Button,
  useMediaQuery,
} from "@mui/material";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useHistory, useLocation } from "react-router-dom";

const AutomationFilter = ({
  sortBy,
  setSortBy,
  setFilterText,
  onTagsChange,
  tags,
  type,
}) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const [searchInput, setSearchInput] = useState(urlParams.get("filter") || "");
  const [searchValue, setSearchValue] = useState("");
  const [selectedTagsLocal, setSelectedTagsLocal] = useState(() => {
    const tags = urlParams.get("tags");
    return tags ? tags.split(",") : ["all"];
  });
  const [showAllTags, setShowAllTags] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const history = useHistory();

  const VISIBLE_TAGS = isSmallScreen ? 4 : 8;
  const visibleTags = showAllTags ? tags : tags.slice(0, VISIBLE_TAGS);

  useEffect(() => {
    setFilterText(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (onTagsChange) {
      onTagsChange(selectedTagsLocal);
    }
  }, [selectedTagsLocal, onTagsChange]);

  useEffect(() => {
    const updates = getUpdates();
    updateURL(updates);
  }, [sortBy, selectedTagsLocal, searchInput]);

  const getUpdates = () => {
    const updates = {
      filter: searchInput,
      tags: selectedTagsLocal.join(","),
      sort: sortBy,
    };

    if (Object.values(updates).some((value) => value && value !== "")) {
      if (type === "taskflow") {
        localStorage.setItem("filterParams-taskflow", JSON.stringify(updates));
      } else {
        localStorage.setItem("filterParams-recurring", JSON.stringify(updates));
      }
    }
    return updates;
  };

  const updateURL = (updates) => {
    const currentParams = new URLSearchParams(location.search);

    Object.entries(updates).forEach(([key, value]) => {
      if (
        value &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }
    });

    const newURL = `${location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, "", newURL);
  };

  const handleParams = () => {
    const savedFilterParams =
      type === "taskflow"
        ? localStorage.getItem("filterParams-taskflow")
        : localStorage.getItem("filterParams-recurring");
    if (savedFilterParams) {
      const parsedParams = JSON.parse(savedFilterParams);
      return parsedParams;
    } else {
      return null;
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchValue(searchInput);
    }
  };

  const handleTagChange = (tag) => {
    let newTags;
    if (tag === "all") {
      newTags = ["all"];
    } else {
      const currentTags = selectedTagsLocal.filter((t) => t !== "all");
      if (currentTags.includes(tag)) {
        newTags = currentTags.filter((t) => t !== tag);
        if (newTags.length === 0) newTags = ["all"];
      } else {
        newTags = [...currentTags, tag];
      }
    }
    setSelectedTagsLocal(newTags);
  };

  const toggleShowAllTags = () => {
    setShowAllTags(!showAllTags);
  };

  return (
    <Card
      sx={{
        mb: 1,
        backgroundColor: "background.paper",
      }}
      elevation={0}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: "1",
              mr: 2,
              height: "100%",
            }}
          >
            <TextField
              fullWidth
              value={searchInput}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Filter title"
              variant="outlined"
              size="small"
              InputProps={{
                sx: {
                  backgroundColor: "background.input",
                  borderRadius: "8px",
                },
              }}
              sx={{
                height: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                },
              }}
            />
          </Box>

          <FormControl
            variant="outlined"
            sx={{
              width: "auto",
            }}
          >
            <Select
              value={sortBy}
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
              size="small"
              sx={{
                borderRadius: "8px",
                backgroundColor: "background.input",
              }}
            >
              <MenuItem value="alphabet">Name (A-Z)</MenuItem>
              <MenuItem value="opp-alphabet">Name (Z-A)</MenuItem>
              <MenuItem value="time">Execution (Latest)</MenuItem>
              <MenuItem value="opp-time">Execution (Oldest)</MenuItem>
              <MenuItem value="count">Count</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mt: 1,
            alignItems: "center",
          }}
        >
          {visibleTags.map((tag) => (
            <Chip
              key={tag}
              label={tag.charAt(0).toUpperCase() + tag.slice(1)}
              onClick={() => handleTagChange(tag)}
              sx={{
                backgroundColor: selectedTagsLocal.includes(tag)
                  ? "primary.main"
                  : "background.input",
                color: selectedTagsLocal.includes(tag)
                  ? "primary.contrastText"
                  : "text.primary",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: selectedTagsLocal.includes(tag)
                    ? "primary.dark"
                    : "action.hover",
                },
                transition: "background-color 0.3s",
                height: "32px",
                borderRadius: "16px",
              }}
            />
          ))}
          {tags.length > VISIBLE_TAGS && (
            <Button
              onClick={toggleShowAllTags}
              startIcon={
                showAllTags ? (
                  <ChevronUp size={16} />
                ) : (
                  <ChevronDown size={16} />
                )
              }
              sx={{
                minWidth: "auto",
                padding: "4px 8px",
                height: "32px",
                color: "text.secondary",
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              {showAllTags ? "Less" : "More"}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default AutomationFilter;
