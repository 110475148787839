export const getCredits = async (apiCall) => {
  try {
    const resp = await apiCall(`/user/credit`, "GET");
    if (resp.error) {
      return null;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const complete = async (
  sendAndWaitWs,
  id,
  scopeId,
  chatId,
  untrusted,
  packContext,
  hard = 0,
  debug,
  requestType,
  autoRun = false
) => {
  try {
    const request = {
      id,
      scopeId,
      chatId,
      untrusted,
      context: packContext,
      allowBadValue: true,
      hard,
      debug,
      requestType,
      autoRun,
    };

    const response = await sendAndWaitWs("/assistant", JSON.stringify(request));
    console.log(response);
    if (response.error) {
      console.log(response);
      return null;
    }
    return JSON.parse(response.payload);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const stt = async (apiCall, audioBlob) => {
  try {
    const formData = new FormData();
    if (audioBlob instanceof Blob) {
      const arrayBuffer = await audioBlob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const rawBlob = new Blob([uint8Array]);
      formData.append("file", rawBlob);
    }

    const jsonData = await apiCall("/assistant/stt", "POST", formData);
    if (!jsonData || jsonData.error) {
      console.error(
        "Error completing assistant task:",
        jsonData ? jsonData.error : "Unknown error"
      );
      return null;
    }
    return jsonData.instruction;
  } catch (error) {
    console.log(error);
    return null;
  }
};
