import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MicIcon from "@mui/icons-material/Mic";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { Link, useHistory, useLocation } from "react-router-dom";
import AudioRecorderDialog from "../common/audio-recorder";
import { useEventEmitter } from "../ws/event-context";
import useApiCall from "../common/api-call";
import { getCredits } from "./assistant-service";
import { useRecoilState } from "recoil";
import { settingBackPathState } from "../global-state";
import Button from "@mui/material/Button";

const InstructionInput = ({
  placeholder,
  onSendAudio,
  onSendText,
  defaultInstruction = "",
}) => {
  const [instruction, setInstruction] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshBalance, setRefreshBalance] = useState(0);
  const [onDemandBalance, setOnDemandBalance] = useState(0);
  const [settingBackPath, setSettingBackPath] =
    useRecoilState(settingBackPathState);
  const { subscribe } = useEventEmitter();
  const location = useLocation();
  const history = useHistory();
  const { apiCall } = useApiCall();
  const theme = useTheme();

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "/credit") {
        const creditData = JSON.parse(data.payload);
        setRefreshBalance(creditData.refreshBalance);
        setOnDemandBalance(creditData.onDemandBalance);
      }
    });
    handleGetCredits();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setInstruction(defaultInstruction);
  }, [defaultInstruction]);

  const handleGetCredits = async () => {
    const resp = await getCredits(apiCall);
    if (resp) {
      setRefreshBalance(resp.refreshBalance);
      setOnDemandBalance(resp.onDemandBalance);
    }
  };

  const totalBalance = refreshBalance + onDemandBalance;

  const handleSend = () => {
    if (instruction.trim() && totalBalance > 0) {
      onSendText(instruction);
      setInstruction("");
    }
  };

  const handleMicClick = () => {
    if (totalBalance > 0) {
      setIsDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleRecorded = (audioBlob) => {
    if (totalBalance > 0) {
      setInstruction("");
      onSendAudio(audioBlob);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.ctrlKey) {
      event.preventDefault();
      handleSend();
    } else if (event.key === "Enter" && event.ctrlKey) {
      setInstruction(instruction + "\n");
    }
  };

  const handleAddCredit = () => {
    setSettingBackPath(location.pathname + location.search + location.hash);
    history.replace("/setting#billing");
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <TextField
          sx={{
            flexGrow: 1,
            mr: 1,
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              minHeight: "50px",
              maxHeight: "180px",
            },
          }}
          variant="outlined"
          placeholder={totalBalance > 0 ? placeholder : "Insufficient balance"}
          value={instruction}
          onChange={(event) => {
            setInstruction(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          disabled={totalBalance === 0}
          multiline
          minRows={1}
          maxRows={6}
          InputProps={{
            sx: {
              backgroundColor: theme.palette.background.input,
              alignItems: "center", // Better alignment for the mic button
              padding: "8px 14px", // Adjust padding for better text placement
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={
                    totalBalance === 0 ? "Insufficient balance" : "Voice input"
                  }
                >
                  <span>
                    <IconButton
                      onClick={handleMicClick}
                      disabled={totalBalance === 0}
                      edge="end"
                    >
                      <MicIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip
          title={totalBalance === 0 ? "Insufficient balance" : "Send message"}
        >
          <span>
            <CustomIconButton
              onClick={handleSend}
              disabled={totalBalance === 0}
            >
              <ArrowUpwardIcon />
            </CustomIconButton>
          </span>
        </Tooltip>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        <Tooltip title="Free-Tier / Credits">
          <CreditBadge>
            <span>🪙</span> {refreshBalance}/{onDemandBalance}
          </CreditBadge>
        </Tooltip>
        {totalBalance <= -100 && (
          <Box
            sx={{
              display: "flex",
              lineHeight: 1,
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <Button
              onClick={handleAddCredit}
              sx={{
                padding: "4px 8px",
                borderRadius: "12px",
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.common.white,
                fontSize: "10px",
                fontWeight: 500,
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.dark,
                },
              }}
            >
              Add Credits
            </Button>
          </Box>
        )}
      </Box>

      <AudioRecorderDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onRecorded={handleRecorded}
      />
    </Box>
  );
};

const CreditBadge = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "2px",
  padding: "2px 6px",
  borderRadius: "12px",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(0, 0, 0, 0.08)",
  fontSize: "12px",
  color: theme.palette.text.secondary,
  "& span": {
    fontSize: "12px",
    lineHeight: 1,
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

export default InstructionInput;
