import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { platformState, userInfoState } from "../global-state";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MSwitch from "../common/switch";

const PhoneSetting = () => {
  const theme = useTheme();
  const history = useHistory();
  const [isPhoneConnected, setIsPhoneConnected] = useState(false);
  const platform = useRecoilValue(platformState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const isApp = platform?.source === "app";
  const [permissions, setPermissions] = useState({});

  const handlePhoneConnection = (enable) => {
    if (window.Android) {
      if (enable) {
        const resp = window.Android.connectPhone();
        if (resp) {
          setIsPhoneConnected(true);
          const pp = window.Android.getPhonePermissions(
            userInfo.activeScope.id
          );
          setPermissions(pp);
        }
      }

      if (!enable) {
        const resp = window.Android.disconnectPhone();
        if (resp) {
          setIsPhoneConnected(false);
        }
      }
    }
  };

  // Modify the useEffect to also check for phone connection status
  useEffect(() => {
    if (isApp && window.Android) {
      const isConnected = window.Android.isPhoneConnected(
        userInfo.activeScope.id
      );
      setIsPhoneConnected(isConnected);
      if (isConnected) {
        const pp = window.Android.getPhonePermissions(userInfo.activeScope.id);
        setPermissions(pp);
      }
    }
  }, [isApp]);

  const handlePermissionChange = (type, enable) => {
    if (window.Android) {
      window.Android.setPhonePermission(type, enable);
    }
  };

  const items = [
    {
      label: "Location",
      description: "Read phone's location",
      type: "location",
    },
    {
      label: "Volume",
      description: "Read & control volume",
      type: "volume",
    },
    {
      label: "Ringer Mode",
      description: "Read & control ringer mode (vibration, silence)",
      type: "ringer",
    },
    {
      label: "Battery",
      description: "Read battery status",
      type: "battery",
    },
  ];

  if (!isApp) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">
          This feature is only available in the app.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        p: { xs: 2, md: 3 },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1.5,
          borderRadius: "15px",
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: "none",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Connect Phone
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
                lineHeight: 1.2,
                display: "block",
              }}
            >
              Connect your phone to your current mHome space.
            </Typography>
          </Box>
          <FormControlLabel
            control={
              <MSwitch
                size="small"
                checked={isPhoneConnected}
                onChange={(event) =>
                  handlePhoneConnection(event.target.checked)
                }
              />
            }
            label=""
            onClick={(event) => event.stopPropagation()}
          />
        </Box>
      </Card>

      {/* PhoneSetting Cards */}
      {isPhoneConnected && (
        <Grid container spacing={1}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 1.5,
                  borderRadius: "15px",
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: "none",
                  opacity: permissions[item.type] ? 1 : 0.6,
                  transition: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        color: theme.palette.text.primary,
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.text.secondary,
                        lineHeight: 1.2,
                        display: "block",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <MSwitch
                        size="small"
                        checked={permissions[item.type]}
                        onChange={(event) =>
                          handlePermissionChange(
                            item.type,
                            event.target.checked
                          )
                        }
                        disabled={item.disabled || false}
                      />
                    }
                    label=""
                    onClick={(event) => event.stopPropagation()}
                  />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Info Message Box */}
      <Card
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: "12px",
          border: "1px solid #EAEEF0",
          boxShadow: "none",
          mt: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
                mr: 2,
              }}
            />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                How Phone Permissions are Used
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This page sets current phone&apos;s available capabilities in
                the current space. For example, when location is enabled, this
                phone&apos;s location data will be reported to mHome within the
                scope of your current space (but not other spaces where this
                phone is also connected to, if any). Some capabilities may
                require system permissions.
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PhoneSetting;
