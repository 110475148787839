import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import { styled } from "@mui/material/styles";

const MIN_RECORDING_TIME = 1000; // 1 second in milliseconds
const MAX_RECORDING_TIME = 60; // 60 seconds

const RecordButton = styled(IconButton)(({ theme, $recording }) => ({
  width: "80px",
  height: "80px",
  backgroundColor: $recording ? "#ff4444" : "#f0f0f0",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: $recording ? "#ff6666" : "#e0e0e0",
  },
  "&:active": {
    backgroundColor: $recording ? "#ff6666" : "#e0e0e0",
  },
  "& .MicIcon": {
    fontSize: "32px",
    color: $recording ? "white" : "#666666",
  },
  "@media (max-width: 600px)": {
    width: "70px",
    height: "70px",
    "& .MicIcon": {
      fontSize: "28px",
    },
  },
}));

const formatDuration = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
};

const AudioRecorderDialog = ({ open, onClose, onRecorded }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recordingStartTimeRef = useRef(null);
  const recordingTimerRef = useRef(null);

  // Effect to handle automatic stop and error when max time is reached
  useEffect(() => {
    if (recordingDuration >= MAX_RECORDING_TIME) {
      handleMaxTimeReached();
    }
  }, [recordingDuration]);

  const handleMaxTimeReached = async () => {
    setError(
      `Maximum recording time of ${MAX_RECORDING_TIME} seconds reached.`
    );
    await stopRecording();
  };

  const startRecording = async () => {
    try {
      setError("");
      setRecordingDuration(0);

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 44100,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });

      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start();
      recordingStartTimeRef.current = Date.now();
      setIsRecording(true);

      recordingTimerRef.current = setInterval(() => {
        const currentDuration =
          (Date.now() - recordingStartTimeRef.current) / 1000;
        setRecordingDuration(currentDuration);
      }, 100);
    } catch (err) {
      setError(
        "Microphone access denied. Please allow microphone access and try again."
      );
    }
  };

  const stopRecording = async () => {
    if (!mediaRecorderRef.current || !isRecording) return null;

    clearInterval(recordingTimerRef.current);

    return new Promise((resolve) => {
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm;codecs=opus",
        });
        resolve(audioBlob);
      };

      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      setIsRecording(false);
    });
  };

  const handleStart = async (event) => {
    event.preventDefault();
    if (!isRecording) {
      await startRecording();
    }
  };

  const handleStop = async (event) => {
    event.preventDefault();
    if (isRecording) {
      const recordingDuration = Date.now() - recordingStartTimeRef.current;

      if (recordingDuration < MIN_RECORDING_TIME) {
        await stopRecording();
        setError("Recording too short. Please hold longer.");
        return;
      }

      setIsProcessing(true);
      try {
        const audioBlob = await stopRecording();
        if (audioBlob) {
          // Only process if within max time
          if (recordingDuration / 1000 <= MAX_RECORDING_TIME) {
            onRecorded(audioBlob);
            handleClose();
          }
        }
      } catch (err) {
        console.error("Error processing audio:", err);
        setError("Failed to process audio. Please try again.");
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleClose = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    clearInterval(recordingTimerRef.current);
    setError("");
    setIsRecording(false);
    setIsProcessing(false);
    setRecordingDuration(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          padding: 2,
          margin: "16px",
          width: "calc(100% - 32px)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          minHeight: "200px",
          justifyContent: "center",
          position: "relative",
          touchAction: "none",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontSize: { xs: "1rem", sm: "1.25rem" },
            textAlign: "center",
            color:
              isRecording && recordingDuration >= MAX_RECORDING_TIME
                ? "error.main"
                : "inherit",
          }}
        >
          {isRecording
            ? `Recording: ${formatDuration(recordingDuration)} (Max: 1:00)`
            : "Tap and hold to record"}
        </Typography>

        <RecordButton
          $recording={isRecording}
          onTouchStart={handleStart}
          onTouchEnd={handleStop}
          onMouseDown={handleStart}
          onMouseUp={handleStop}
          onMouseLeave={handleStop}
          disabled={isProcessing}
          sx={{ touchAction: "none" }}
        >
          {isProcessing ? (
            <CircularProgress size={32} />
          ) : (
            <MicIcon className="MicIcon" />
          )}
        </RecordButton>

        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{
              position: "absolute",
              bottom: 16,
              textAlign: "center",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default AudioRecorderDialog;
