import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  developerEmailState,
  developerIdTokenState,
  linkOrgIdState,
  linkOrgUserIdState,
  originalPathState,
  platformState,
  userIdTokenState,
  userInfoState,
} from "../global-state";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import LoadingSpinner from "../common/loading";
import ScriptEditView from "../automation/script-edit-view";
import AutomationHistoryList from "../automation/automation-history-list";
import AutomationHistoryStatistics from "../menu/automation-history-statistics";
import AutomationStatistics from "../menu/automation-statistics";
import OverviewConfigView from "../automation/overview-config-view";
import "./user-app.css";
import AutomationListView from "../automation/automation-list-view";
import PremadeGalleryView from "../premade/premade-gallery-view";
import PremadeScriptView from "../premade/premade-script-view";
import SettingView from "../setting/setting-view";
import NotificationDialogSystem from "./notification-dialog-system";
import DashboardView from "../dashboard/dashboard-view";
import TaskFlowListView from "../taskflow/task-flow-list-view";
import { useBackdrop } from "../common/backdrop";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HistoryIcon from "@mui/icons-material/History";
import EditOffIcon from "@mui/icons-material/EditOff";
import GridOnIcon from "@mui/icons-material/GridOn";
import GridOffIcon from "@mui/icons-material/GridOff";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AppBar from "@mui/material/AppBar";
import ExtensionIcon from "@mui/icons-material/Extension";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AutomationDebugView from "../automation/automation-debug-view";
import ChatView from "../chat/chat-view";
import { Bot } from "lucide-react";
import AddIcon from "@mui/icons-material/Add";
import { useAuthCheck } from "../auth/auth-check";
import IntegrationListView from "../integration/integration-list-view";
import Menu from "../menu/menu";
import Scope from "../menu/scope";
import IntegrationDetailView from "../integration/integration-detail-view";
import { Fab, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SchedulesListView from "../menu/schedule";
import TaskFlowTempView from "../taskflow/task-flow-temp-view";
import PhoneSetting from "../menu/phone-setting";

const UserApp = ({ history }) => {
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const [notificationStatus, setNotificationStatus] = useState("");
  const path = useLocation();
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const theme = useTheme();
  const location = useLocation();
  const [edit, setEdit] = useState(false);
  const [drag, setDrag] = useState(false);
  const { userAuthState, setUserAuthState, checkAuthState } = useAuthCheck();
  const [showFilter, setShowFilter] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [openAppbar, setOpenAppbar] = useState(true);

  const [notificationListOpen, setNotificationListOpen] = React.useState(false);

  const handleEdit = useCallback(() => {
    setEdit((prevEdit) => !prevEdit);
  }, []);

  const handleDrag = useCallback(() => {
    setDrag((prevDrag) => !prevDrag);
  }, []);

  const handleAdd = () => {
    if (currentRoute.path === "/automation/list") {
      history.push(`/automation/overview?mode=new`);
    } else if (currentRoute.path === "/taskflow/list") {
      history.push(`/automation/overview?mode=new`);
    } else if (currentRoute.path === "/premade/gallery") {
      history.push(`/premade/script`);
    }
  };

  const devPath = [
    "/automation/script",
    "/automation/debug",
    "/premade/script",
  ];

  const routes = [
    {
      path: "/dashboard",
      exact: true,
      component: DashboardView,
      title: "Dashboard",
      showEdit: true,
      showAdd: false,
      showBack: false,
      showDrag: true,
      showNav: true,
      showNotification: true,
      props: { edit, drag },
    },
    {
      path: "/chat",
      exact: true,
      component: ChatView,
      title: "Assistant",
      showEdit: false,
      showAdd: false,
      showBack: true,
      props: {},
      backPath: "/dashboard",
    },
    {
      path: "/automation/script",
      exact: true,
      title: "Automation Script",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: ScriptEditView,
      backPath: "/automation/list",
    },
    {
      path: "/automation/debug",
      exact: true,
      title: "Automation Debug",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: AutomationDebugView,
      backPath: "/automation/list",
    },
    {
      path: "/automation/history",
      exact: true,
      title: "Automation History",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: AutomationHistoryList,
      backPath: "/automation/history",
    },
    {
      path: "/automation/history/statistics",
      exact: true,
      title: "History Statistics",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: AutomationHistoryStatistics,
      backPath: "/automation/history",
    },

    {
      path: "/automation/overview",
      exact: true,
      title: "Automation",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: OverviewConfigView,
      backPath: "/automation/list",
    },
    {
      path: "/automation/list",
      exact: true,
      title: "Recurring Automation",
      showEdit: false,
      showAdd: true,
      showBack: false,
      showNotification: true,
      showNav: true,
      component: AutomationListView,
      props: { showFilter: showFilter, setShowFilter: setShowFilter },
    },
    {
      path: "/premade/gallery",
      exact: true,
      title: "Premade Automation",
      showEdit: false,
      showAdd: true,
      showBack: false,
      showNotification: true,
      showNav: true,
      component: PremadeGalleryView,
      props: {
        showFilter: showFilter,
        setShowFilter: setShowFilter,
      },
    },
    {
      path: "/premade/script",
      exact: true,
      title: "Premade Automation",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: PremadeScriptView,
      backPath: "/premade/gallery",
    },
    {
      path: "/setting",
      exact: true,
      title: "Setting",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: SettingView,
      backPath: "/menu",
    },
    {
      path: "/taskflow/list",
      exact: true,
      title: "TaskFlow Automation",
      showEdit: false,
      showAdd: true,
      showBack: false,
      showNotification: true,
      showNav: true,
      component: TaskFlowListView,
      props: { showFilter: showFilter, setShowFilter: setShowFilter },
    },

    {
      path: "/taskflow/temps",
      exact: true,
      title: "History Tasks",
      showBack: true,
      component: TaskFlowTempView,
      backPath: "/taskflow/list",
    },
    {
      path: "/menu",
      exact: true,
      title: "Menu",
      showEdit: false,
      showAdd: false,
      showBack: false,
      showNotification: true,
      showNav: true,
      component: Menu,
      props: { showFilter: showFilter, setShowFilter: setShowFilter },
    },
    {
      path: "/space",
      exact: true,
      title: "Space",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: Scope,
      backPath: "/menu",
    },
    {
      path: "/automation/statistics",
      exact: true,
      title: "Automation Statistics",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: AutomationStatistics,
      backPath: "/menu",
    },
    {
      path: "/integerations",
      exact: true,
      title: "Integerations",
      showEdit: false,
      showAdd: false,
      showBack: true,
      showNotification: true,
      component: IntegrationListView,
      props: { showFilter: showFilter, setShowFilter: setShowFilter },
      backPath: "/menu",
    },
    {
      path: "/integration",
      exact: true,
      title: "Integration",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: IntegrationDetailView,
      backPath: "/integerations",
      props: { showFilter: showFilter, setShowFilter: setShowFilter },
    },
    {
      path: "/schedules",
      exact: true,
      title: "Schedule",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: SchedulesListView,
      backPath: "/menu",
    },
    {
      path: "/phone/setting",
      exact: true,
      title: "Phone Setting",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: PhoneSetting,
      backPath: "/menu",
    },
    {
      path: "/notifications",
      exact: true,
      title: "Notifications",
      showEdit: false,
      showAdd: false,
      showBack: true,
      component: NotificationDialogSystem,
      backPath: "/dashboard",
      props: {
        listOpen: notificationListOpen,
        setListOpen: setNotificationListOpen,
        setNotificationStatus: setNotificationStatus,
      },
    },
  ];

  useEffect(() => {
    if (location.pathname === "/setting" && location.hash && isSmallScreen) {
      setOpenAppbar(false);
    } else {
      setOpenAppbar(true);
    }
  }, [location, isSmallScreen, setOpenAppbar]);

  useEffect(() => {
    if (notificationListOpen) {
      if (location.pathname !== "/notifications" && isSmallScreen) {
        history.push("/notifications");
      } else if (location.pathname === "/notifications" && !isSmallScreen) {
        if (history.length > 1) {
          history.goBack();
        } else {
          history.replace(currentRoute.backPath);
        }
      }
    }
  }, [location.pathname, isSmallScreen, notificationListOpen]);

  const getCurrentRoute = () => {
    let r;
    try {
      r = routes.find((route) => route.path === path.pathname);
      if (!r) {
        r = routes[0];
      }
    } catch (error) {
      r = routes[0];
    }

    if (r.path === "/integration") {
      r = {
        ...r,
        hash: location.hash,
      };
    }

    return r;
  };

  const currentRoute = getCurrentRoute();

  useEffect(() => {
    if (!history) {
      return;
    }
    setOriginalPath(path.pathname + path.search + path.hash);
    checkAuthState();
    setCurTab(getCurrentTab());

    const intervalId = setInterval(() => {
      console.log("Refresh token!");
      checkAuthState();
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [history]);

  const handleNotificationIconClick = () => {
    if (isSmallScreen) {
      setNotificationListOpen(!notificationListOpen);
      history.push("/notifications");
    } else {
      setNotificationListOpen(!notificationListOpen);
    }
  };

  const handleHistoryIconClick = () => {
    history.push("/taskflow/temps");
  };

  const getCurrentTitle = () => {
    return currentRoute ? currentRoute.title : "";
  };

  const [curTab, setCurTab] = React.useState(-1);

  const getCurrentTab = () => {
    if (location.pathname === "/dashboard") return 0;
    if (location.pathname === "/automation/list") return 1;
    if (location.pathname === "/taskflow/list") return 2;
    if (location.pathname === "/premade/gallery") return 3;
    if (location.pathname === "/menu") return 4;
    return 0;
  };

  const handleSettingsClick = () => {
    history.push("/setting");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: theme.palette.background.default,
      }}
    >
      {!userAuthState ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              width: "100%",
              paddingBottom: currentRoute.showNav ? "74px" : "10px",
            }}
          >
            {openAppbar && (
              <AppBar
                position="fixed"
                sx={{
                  backgroundColor: theme.palette.background.default,
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Toolbar>
                  <>
                    {currentRoute && currentRoute.showBack && (
                      <IconButton
                        color="inherit"
                        aria-label="overview"
                        edge="start"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          if (location.pathname === "/notifications") {
                            setNotificationListOpen(false);
                          }
                          if (history.length > 1) {
                            if (location.pathname.startsWith("/setting")) {
                              history.push("/menu");
                            } else {
                              history.goBack();
                            }
                          } else {
                            history.replace(currentRoute.backPath);
                          }
                        }}
                      >
                        <ArrowBackIcon
                          sx={{
                            color: theme.palette.text.primary,
                          }}
                        />
                      </IconButton>
                    )}
                    <Typography
                      variant="h6"
                      component="div"
                      noWrap
                      sx={{
                        flexGrow: 1,
                        color: theme.palette.text.primary,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {getCurrentTitle()}
                    </Typography>
                    {(currentRoute.path === "/premade/gallery" ||
                      currentRoute.path === "/automation/list" ||
                      currentRoute.path === "/taskflow/list" ||
                      currentRoute.path === "/menu/integrations" ||
                      (currentRoute.path === "/integration" &&
                        currentRoute.hash !== "#info")) && (
                      <IconButton
                        color="inherit"
                        aria-label="filter"
                        onClick={() => {
                          if (showFilter) {
                            if (
                              location.pathname.startsWith("/taskflow/list")
                            ) {
                              localStorage.removeItem("filterParams-taskflow");
                            } else if (
                              location.pathname.startsWith("/automation/list")
                            ) {
                              localStorage.removeItem("filterParams-recurring");
                            }
                          }
                          setShowFilter(!showFilter);
                        }}
                      >
                        <FilterListIcon
                          sx={{
                            color: showFilter
                              ? theme.palette.text.active
                              : theme.palette.text.primary,
                          }}
                        />
                      </IconButton>
                    )}

                    {currentRoute && currentRoute.showEdit && (
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        onClick={handleEdit}
                      >
                        {edit ? (
                          <EditOffIcon
                            sx={{
                              color: theme.palette.text.active,
                            }}
                          />
                        ) : (
                          <EditIcon
                            sx={{
                              color: theme.palette.text.primary,
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                    {currentRoute && currentRoute.showDrag && (
                      <IconButton
                        color={drag ? "secondary" : "inherit"}
                        aria-label="drag"
                        onClick={handleDrag}
                      >
                        {drag ? (
                          <GridOffIcon
                            sx={{
                              color: theme.palette.text.active,
                            }}
                          />
                        ) : (
                          <GridOnIcon
                            sx={{
                              color: theme.palette.text.primary,
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                    {currentRoute && currentRoute.showAdd && (
                      <>
                        {path.pathname === "/premade/gallery" &&
                        !developerIdToken ? (
                          <></>
                        ) : (
                          <IconButton
                            color="inherit"
                            aria-label="add"
                            onClick={handleAdd}
                          >
                            <AddIcon
                              sx={{
                                color: theme.palette.text.primary,
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </>

                  {currentRoute.showHistory && (
                    <IconButton
                      color="inherit"
                      aria-label="history"
                      onClick={handleHistoryIconClick}
                    >
                      <HistoryIcon
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      />
                    </IconButton>
                  )}

                  {currentRoute.showNotification && (
                    <IconButton
                      style={{
                        color:
                          notificationStatus === "d"
                            ? theme.palette.text.invalid
                            : notificationStatus === "n"
                            ? theme.palette.text.active
                            : theme.palette.text.primary,
                      }}
                      aria-label="notifications"
                      onClick={handleNotificationIconClick}
                    >
                      <NotificationsIcon />
                    </IconButton>
                  )}

                  {location.pathname === "/menu" && (
                    <IconButton
                      aria-controls="account-menu"
                      aria-haspopup="true"
                      onClick={handleSettingsClick}
                      size="large"
                      edge="end"
                      color="inherit"
                    >
                      <SettingsIcon
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      />
                    </IconButton>
                  )}
                </Toolbar>
              </AppBar>
            )}

            {openAppbar && (
              <Box
                sx={(theme) => ({
                  height: {
                    xs: theme.mixins.toolbar.minHeight,
                    sm: theme.mixins.toolbar.minHeight,
                    md: theme.mixins.toolbar["@media (min-width:600px)"]
                      .minHeight,
                  },
                  flexShrink: 0,
                })}
              />
            )}
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: theme.palette.background.default,
                width: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Switch>
                {routes
                  .filter((route) => {
                    return (
                      devPath.indexOf(route.path) === -1 ||
                      userInfo.setting.engAccount
                    );
                  })
                  .map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      render={(props) => (
                        <route.component {...props} {...route.props} />
                      )}
                    />
                  ))}
                <Route path="*">
                  <Redirect to="/dashboard" />
                </Route>
              </Switch>
            </Box>
            {currentRoute && currentRoute.showNav && (
              <Paper
                sx={{
                  position: "fixed",
                  bottom: 16,
                  left: 16,
                  right: 16,
                  zIndex: 10,
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
                elevation={3}
              >
                <BottomNavigation
                  showLabels
                  value={curTab}
                  onChange={(event, newValue) => {
                    setCurTab(newValue);
                    if (newValue === 0) {
                      history.push("/dashboard");
                    } else if (newValue === 1) {
                      history.push("/automation/list");
                    } else if (newValue === 2) {
                      history.push("/taskflow/list");
                    } else if (newValue === 3) {
                      history.push("/premade/gallery");
                    } else if (newValue === 4) {
                      history.push("/menu");
                    }
                  }}
                  sx={{
                    bgcolor: theme.palette.background.white,
                    height: "48px", // Default height for BottomNavigation
                    "& .MuiBottomNavigationAction-root": {
                      minWidth: "50px",
                      padding: "4px 0",
                    },
                    "& .MuiBottomNavigationAction-label": {
                      fontSize: "8px",
                      transition: "font-size 0.2s ease", // Smooth transition
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                      transition: "font-size 0.2s ease", // Smooth transition
                    },
                    "& .Mui-selected": {
                      "& .MuiBottomNavigationAction-label": {
                        fontSize: "10px", // Slightly larger font size for selected label
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "22px", // Slightly larger icon size for selected icon
                      },
                    },
                  }}
                >
                  <BottomNavigationAction
                    label="Dashboard"
                    icon={<DashboardIcon />}
                  />
                  <BottomNavigationAction
                    label="Automation"
                    icon={<AutoModeIcon />}
                  />
                  <BottomNavigationAction
                    label="TaskFlow"
                    icon={<PlayCircleIcon />}
                  />
                  <BottomNavigationAction
                    label="Premade"
                    icon={<AutoAwesomeIcon />}
                  />
                  <BottomNavigationAction label="Menu" icon={<MenuIcon />} />
                </BottomNavigation>
              </Paper>
            )}
            {location.pathname !== "/notifications" && (
              <NotificationDialogSystem
                listOpen={notificationListOpen}
                setListOpen={setNotificationListOpen}
                setNotificationStatus={setNotificationStatus}
              ></NotificationDialogSystem>
            )}
          </Box>
          {currentRoute && currentRoute.showNav && (
            <Fab
              color="primary"
              size="medium"
              sx={{
                position: "fixed",
                bottom: 72, // Reduced from 88 to be closer to bottom nav bar
                right: 16,
                zIndex: 1,
                textTransform: "none",
              }}
              onClick={() => history.push("/chat")}
            >
              <Bot size={32} />
            </Fab>
          )}
        </>
      )}
    </Box>
  );
};

export default UserApp;
